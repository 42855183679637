import { Button, Icon, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";
import { useServices } from "services/useServices";

export const AudioPlayer = ({
  arrayBuffer,
  isReadingLocked,
  autoplay,
}: {
  autoplay: boolean;
  arrayBuffer?: ArrayBuffer;
  isReadingLocked: boolean;
}) => {
  const volumeRef = React.useRef<number>(1);
  const ref = React.useRef<HTMLAudioElement | null>(null);
  const { tracking } = useServices();

  const [audioUrl, setAudioUrl] = React.useState<string | null>(null);
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (arrayBuffer) {
      const blob = new Blob([arrayBuffer], { type: "audio/mpeg" });
      const url = URL.createObjectURL(blob);
      setAudioUrl(url);

      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [arrayBuffer]);

  React.useEffect(() => {
    if (autoplay) {
      setIsPlaying(true);
    }
  }, [autoplay]);

  React.useEffect(() => {
    if (isPlaying) {
      ref.current?.play();
    } else {
      ref.current?.pause();
    }
  }, [isPlaying]);

  // Sets the volume down if the reading is locked
  React.useEffect(() => {
    let interval: number | null = null;

    if (isReadingLocked) {
      if (isPlaying) {
        interval = window.setInterval(() => {
          if (ref.current) {
            volumeRef.current = volumeRef.current - 0.05;
            ref.current.volume = volumeRef.current < 0 ? 0 : volumeRef.current;

            if (interval && volumeRef.current <= 0) {
              clearInterval(interval);
              // resets audio to the start
              setIsPlaying(false);
              ref.current.pause();
              ref.current.currentTime = 0;
              volumeRef.current = 1;
            }
          }
        }, 500);
      } else {
        if (interval) {
          clearInterval(interval);
        }
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isPlaying, isReadingLocked]);

  if (!audioUrl) {
    return null;
  }

  return (
    <>
      <Stack direction={"row"} spacing={2} alignItems={"center"}>
        <Button
          size={"md"}
          colorScheme="teal"
          onClick={() => {
            if (isPlaying) {
              tracking.capture("audio-reading-paused");
            } else {
              tracking.capture("audio-reading-started");
            }

            setIsPlaying((prev) => !prev);
          }}
        >
          {isPlaying ? (
            <Icon as={FaPause} boxSize={3} />
          ) : (
            <Icon as={FaPlay} boxSize={3} />
          )}
        </Button>

        <Text color={"teal.500"} fontSize={"sm"}>
          (Audio answer)
        </Text>
      </Stack>

      <audio
        src={audioUrl}
        ref={ref}
        style={{ padding: 0 }}
        onEnded={() => {
          setIsPlaying(false);
        }}
      >
        Your browser does not support the audio element.
      </audio>
    </>
  );
};
