import { UseToastOptions, useToast } from "@chakra-ui/react";
import React from "react";

export type NotificationStoreType = {
  show: (options: UseToastOptions) => void;
};

export const NoticicationStoreCtx =
  React.createContext<NotificationStoreType | null>(null);

export function NotificationStore({ children }: React.PropsWithChildren) {
  const toast = useToast();

  const value = React.useMemo<NotificationStoreType>(() => {
    return {
      show: toast,
    };
  }, []);

  return (
    <NoticicationStoreCtx.Provider value={value}>
      {children}
    </NoticicationStoreCtx.Provider>
  );
}
