import { Box, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { IoSparklesSharp } from "react-icons/io5";
import { Resource } from "src/types";

import { Skeleton } from "./Skeleton";

export function ResourceRenderer<T>({
  resource,
  children,
  fallback,
  loadingText,
}: {
  resource: Resource<T>;
  fallback?: string | React.ReactNode;
  loadingText?: string;
  children?: (input: { data: T }) => React.ReactElement;
}) {
  if (resource.status === "initial") {
    return null;
  }

  if (resource.status === "loading") {
    return (
      <Box width={"full"} position={"relative"}>
        <Skeleton isLoaded={false} height={"100px"} />

        {loadingText && (
          <Text
            position={"absolute"}
            fontSize={"sm"}
            fontWeight={"normal"}
            lineHeight={1}
            textAlign={"center"}
            top={"50%"}
            transform={"translateY(-50%)"}
            width={"100%"}
          >
            {loadingText}
          </Text>
        )}
      </Box>
    );
  }

  if (resource.status === "error") {
    if (!fallback) {
      return null;
    }

    if (typeof fallback === "string") {
      return (
        <Text
          my={4}
          textAlign={"center"}
          color={"fallbackText"}
          maxW={200}
          mx={"auto"}
        >
          {fallback}{" "}
          <Icon as={IoSparklesSharp} boxSize={4} color={"fallbackText"} />
        </Text>
      );
    }

    return fallback;
  }

  if (children) {
    return children({ data: resource.data });
  }

  return null;
}
