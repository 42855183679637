import { Icon, Stack, StackProps, Text } from "@chakra-ui/react";
import { FaCheck } from "react-icons/fa";
import { FaUnlockAlt } from "react-icons/fa";
import { useNavigate } from "react-router";
import { InstantReading } from "services/coreapi";

export function ReadingListItem({
  reading,
  isLocked,
  ...rest
}: { reading: InstantReading; isLocked: boolean } & StackProps) {
  const navigate = useNavigate();

  return (
    <Stack
      bg="#805ad529"
      py={2}
      px={3}
      key={reading.id}
      cursor={"pointer"}
      onClick={() => {
        navigate(`/astrologer/instant-readings/${reading.id}`);
      }}
      width={"full"}
      borderRadius={"lg"}
      {...rest}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={3}
      >
        <Stack direction={"row"} alignItems={"center"} gap={1}>
          <Stack
            spacing={1}
            px={2}
            direction={"row"}
            flexShrink={0}
            height={"25px"}
            justifyContent={"center"}
            alignItems={"center"}
            bg="whiteAlpha.300"
            _groupHover={{
              bg: "whiteAlpha.300",
            }}
            transition={"ease-in 100ms"}
            borderRadius={"lg"}
            flexGrow={0}
            position={"relative"}
          >
            <Icon as={FaCheck} boxSize={3} color={"green.300"} />

            <Text fontSize={"xs"} fontWeight={"semibold"} color="purple.50">
              Asked
            </Text>
          </Stack>

          {isLocked && (
            <Stack
              spacing={1}
              px={2}
              direction={"row"}
              flexShrink={0}
              height={"25px"}
              justifyContent={"center"}
              alignItems={"center"}
              bg="purple.500"
              _groupHover={{
                bg: "purple.500",
              }}
              transition={"ease-in 100ms"}
              borderRadius={"lg"}
              flexGrow={0}
              position={"relative"}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/summary`);
              }}
            >
              <Icon as={FaUnlockAlt} boxSize={3} color={"green.300"} />

              <Text fontSize={"xs"} fontWeight={"semibold"} color="purple.50">
                Unlock
              </Text>
            </Stack>
          )}
        </Stack>

        <Text fontSize={"xs"} color="gray.600">
          {new Intl.DateTimeFormat(navigator.language || "en-US", {
            month: "short",
            day: "numeric",
            year: "2-digit",
            hour: "numeric",
            minute: "numeric",
          }).format(new Date(reading.asked_at))}
        </Text>
      </Stack>

      <Stack spacing={1}>
        <Text fontSize={"md"} fontWeight={"semibold"} color="gray.100">
          {reading.original_question_text}
        </Text>

        <Text fontSize={"xs"} color="gray.400" noOfLines={1} maxW={"full"}>
          {reading.answer_text}
        </Text>
      </Stack>
    </Stack>
  );
}
