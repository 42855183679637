import React from "react";
import { useReadingRepo } from "repositories/useReadingRepo";
import { CoreApiDomainError, InstantReading } from "services/coreapi";

type Result =
  | {
      status: "initial";
    }
  | {
      status: "loading";
    }
  | {
      status: "ok";
      data: InstantReading;
    }
  | {
      status: "error";
    }
  | {
      status: "error-inappropriate";
      data: { answer: string };
    };

export function useReading(): {
  create: (question: string) => Promise<void>;
  result: Result;
} {
  const readingRepo = useReadingRepo();

  const [result, setResult] = React.useState<Result>({
    status: "initial",
  });

  return {
    create: async (question: string) => {
      try {
        if (result.status === "loading") {
          return;
        }

        setResult({
          status: "loading",
        });

        const res = await readingRepo.createInstantReading(question);

        setResult({
          status: "ok",
          data: res.answer,
        });
      } catch (err) {
        // TODO: handle properly
        if (
          err instanceof CoreApiDomainError &&
          err.type === "inappropriate-question-asked"
        ) {
          setResult({
            status: "error-inappropriate",
            data: {
              answer: err.userMessage ?? "",
            },
          });

          return;
        }

        setResult({
          status: "error",
        });
      }
    },
    result,
  };
}
