const email =
  /(?=[a-z0-9@.!#$%&'*+/=?^_‘{|}~-]{6,254})(?=[a-z0-9.!#$%&'*+/=?^_‘{|}~-]{1,64}@)[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:(?=[a-z0-9-]{1,63}\.)[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?=[a-z0-9-]{1,63})[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export function isEmailValid(input: string): boolean {
  return email.test(input.toLowerCase());
}

// prints "March 19, 2024, 9:49:10 AM" for en-US
export function formatDateTime(
  date: Date,
  options?: { timeZone?: string },
): string {
  return new Intl.DateTimeFormat(navigator.language || "en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    ...options,
  }).format(date);
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatToTimeString(hour: number, minute: number): string {
  // Validate input
  if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
    throw new Error(
      "Invalid input: Hour must be between 0-23 and minute must be between 0-59",
    );
  }

  // Pad hour and minute with leading zeros if necessary
  const formattedHour = hour.toString().padStart(2, "0");
  const formattedMinute = minute.toString().padStart(2, "0");

  // Return the formatted string
  return `${formattedHour}:${formattedMinute}`;
}
