import React from "react";

import { ServicesCtx, ServicesType } from "./Services";
import "./coreapi";

export function useServices(): ServicesType {
  const ctx = React.useContext(ServicesCtx);
  if (!ctx) {
    throw new Error("useServices must be used within ServiceProvider");
  }

  return ctx;
}
