export interface ItemWithDuration {
  item: string;
  duration: number;
}

export function addDurations(
  items: string[],
  totalDuration: number,
): ItemWithDuration[] {
  const result: ItemWithDuration[] = [];
  const numberOfItems = items.length;
  const averageDuration = totalDuration / numberOfItems;

  let remainingDuration = totalDuration;

  items.forEach((item, index) => {
    // Calculate a random percentage between 0 and 20
    const randomPercentage = (Math.random() * 20) / 100;
    const variation = averageDuration * randomPercentage;

    // Calculate a random duration for the current item within the variation range
    const randomDuration =
      averageDuration + (Math.random() < 0.5 ? -1 : 1) * variation;

    // Adjust remaining duration
    remainingDuration -= randomDuration;

    // Add the item with its duration to the result
    result.push({
      item,
      duration: randomDuration,
    });

    // Adjust durations to ensure the total duration remains constant
    if (index === numberOfItems - 1) {
      // If it's the last item, make sure the total duration is accurate
      result[index].duration += remainingDuration;
    }
  });

  return result;
}
