import { Stack, StackProps, Text } from "@chakra-ui/react";
import { useRootStore } from "stores/useRootStore";

import { LifetimePlan } from "./LifetimePlan";
import { MonthlyPlan } from "./MonthlyPlan";
import { RiskFreeGuaranteed } from "./RiskFreeGuaranteed";
import { SafeCheckout } from "./SafeCheckout";

export function PricingSection({
  sectionID,
  onCTAClick,
  ...rest
}: StackProps & {
  sectionID: string;
  onCTAClick: (sectionID: string, planID: string) => void;
}) {
  const { pricingPlans } = useRootStore();

  const lifetimePlan = pricingPlans.find(
    (it) => it.lookupID === "lifetime-license",
  );
  const monthlyPlan = pricingPlans.find((it) => it.lookupID === "1-month-plan");

  return (
    <Stack spacing={4} id={sectionID} mt={12} {...rest}>
      <Text
        textAlign={"center"}
        fontSize={"2xl"}
        fontWeight={"semibold"}
        lineHeight={1.3}
        color={"white"}
        px={2}
        mb={3}
      >
        Unlock Your Astrologer By Choosing Your Plan Now
      </Text>

      <Text
        textAlign={"center"}
        fontSize={"md"}
        lineHeight={1.3}
        color={"white"}
        px={2}
        mb={3}
      >
        Explore unlimited questions, delve deep into relationships, career
        paths, and personality insights. Receive daily horoscopes and gain
        perspective on your potential future.
      </Text>

      {monthlyPlan && (
        <MonthlyPlan
          plan={monthlyPlan}
          onSelect={() => {
            onCTAClick(sectionID, monthlyPlan.lookupID);
          }}
        />
      )}

      {lifetimePlan && (
        <LifetimePlan
          plan={lifetimePlan}
          onSelect={() => {
            onCTAClick(sectionID, lifetimePlan.lookupID);
          }}
        />
      )}

      <SafeCheckout />
      <RiskFreeGuaranteed />
    </Stack>
  );
}
