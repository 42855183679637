import { Button, Stack, Text } from "@chakra-ui/react";
import * as React from "react";
import {
  gaTrackPaidTrialPurchaseConversion,
  trackPixelEvent,
} from "services/tracking";
import { useServices } from "services/useServices";

export function createSuccessCheckoutURL(
  paymentType: "express" | "card",
  pricePaid: number,
  currency: string,
  planID: string,
) {
  // this is a redirect url after the successful payment
  const redirectUrl = new URL(window.location.href);
  redirectUrl.searchParams.delete("checkout");

  const currentPathname = redirectUrl.pathname;
  redirectUrl.pathname = "/success-checkout";

  redirectUrl.searchParams.set("paymentType", paymentType);
  redirectUrl.searchParams.set("pricePaid", pricePaid.toString());
  redirectUrl.searchParams.set("currency", currency);
  redirectUrl.searchParams.set("planID", planID);
  redirectUrl.searchParams.set("prevPathname", currentPathname);

  return redirectUrl;
}

type UrlParts = ReturnType<typeof parseRedirectURL>;

function parseRedirectURL() {
  const redirectUrl = new URL(window.location.href);
  const paymentType = redirectUrl.searchParams.get("paymentType");
  const pricePaidStr = redirectUrl.searchParams.get("pricePaid");
  const pricePaid = pricePaidStr ? parseFloat(pricePaidStr) : null;
  const currency = redirectUrl.searchParams.get("currency");
  const planID = redirectUrl.searchParams.get("planID");
  const prevPathname = redirectUrl.searchParams.get("prevPathname");

  return {
    paymentType,
    pricePaid,
    currency,
    planID,
    prevPathname,
  };
}

export function SuccessCheckoutPage() {
  const { tracking } = useServices();

  React.useEffect(() => {
    const urlParts = parseRedirectURL();
    trackSuccessPayment(urlParts);
  }, []);

  function trackSuccessPayment(urlParts: UrlParts) {
    const pricePaid = (urlParts.pricePaid ?? 0) / 100;

    trackPixelEvent("Purchase", {
      currency: urlParts.currency,
      value: pricePaid,
      paymentType: urlParts.paymentType,
      planID: urlParts.planID,
    });

    gaTrackPaidTrialPurchaseConversion({
      value: pricePaid,
      currency: urlParts.currency ?? "",
    });

    tracking.capture("purchase", {
      currency: urlParts.currency,
      value: pricePaid,
      paymentType: urlParts.paymentType,
      planID: urlParts.planID,
    });
  }

  return (
    <Stack textAlign={"center"}>
      <Text fontSize={"xl"} fontWeight={"bold"}>
        Thank your for your purchase
      </Text>
      <Text>You can now use your astrologer without any limits</Text>
      <Button
        onClick={() => {
          window.location.replace("/astrologer");
        }}
      >
        Use astrologer
      </Button>
    </Stack>
  );
}
