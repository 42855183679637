import { Box, Fade, Image, StackProps } from "@chakra-ui/react";
import { getAstrologerOrDefault } from "lib/astrologers";
import { useRootStore } from "stores/useRootStore";

import { Orb } from "../Orb";

export function AstrologerAvatar({
  animationDuration = 3000,
}: { animationDuration?: number } & StackProps) {
  const ORB_SIZE = 180;
  const rootStore = useRootStore();

  const avatar = getAstrologerOrDefault(rootStore.user.astrologer_persona_id);

  return (
    <Box position={"relative"} mx="auto">
      <Fade
        in={animationDuration > 1000}
        transition={{ exit: { duration: 1 }, enter: { duration: 1 } }}
        unmountOnExit
      >
        <Image
          src={avatar.avatarRegular}
          height={ORB_SIZE}
          width={ORB_SIZE}
          position={"absolute"}
          borderRadius={"full"}
        />
      </Fade>

      <Fade
        in={animationDuration <= 1000}
        transition={{ exit: { duration: 1 }, enter: { duration: 1 } }}
        unmountOnExit
      >
        <Image
          src={avatar.avatarThinking}
          height={ORB_SIZE}
          width={ORB_SIZE}
          position={"absolute"}
          borderRadius={"full"}
        />
      </Fade>

      <Orb mx={"auto"} size={ORB_SIZE} duration={animationDuration} />
    </Box>
  );
}
