import {
  Box,
  Button,
  Divider,
  Icon,
  Stack,
  StackProps,
  Text,
} from "@chakra-ui/react";
import { formatDateTime } from "lib/strings";
import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router";

import { AstrologerQuestionBtn } from "./AstrologerQuestionBtn";

export function AstrologerReading({
  askedAt,
  answer,
  determinantAspects,
  suggestedQuestions,
  isLocked,
}: {
  id?: string;
  askedAt: string;
  answer: string;
  determinantAspects?: string[];
  suggestedQuestions?: string[];
  isLocked: boolean;
}) {
  const text = isLocked ? answer.slice(0, 60) : answer;

  return (
    <Stack spacing={3} alignItems={"flex-start"} width={"100%"}>
      <Text fontSize={"xs"} color="text.400">
        {formatDateTime(new Date(askedAt))}
      </Text>

      <Stack spacing={0} position={"relative"}>
        <Text mb={5} whiteSpace={"pre-wrap"}>
          {text}{" "}
          {isLocked && (
            <Text as="span" filter="blur(2px)">
              {RandomText}
            </Text>
          )}
        </Text>

        {isLocked && (
          <Stack
            spacing={0}
            position={"absolute"}
            left={0}
            bottom={0}
            width={"100%"}
            height={"90%"}
          >
            <Box
              height={"100%"}
              backgroundColor={"#0b141585"}
              backdropFilter="blur(5px)"
              style={{
                mask: "linear-gradient(transparent , black 60px)",
              }}
            />

            <CTA mt={"60px"} />
            <CTA bottom={0} />
          </Stack>
        )}
      </Stack>

      {determinantAspects && determinantAspects.length > 0 && (
        <>
          <Divider my={6} borderColor={"teal.700"} />

          <Stack>
            <Text fontSize={"sm"} color="text.400" fontWeight={"semibold"}>
              Determining Aspects
            </Text>

            <Stack>
              {determinantAspects.map((it, idx) => {
                return (
                  <Text key={idx} fontSize={"sm"}>
                    - {it}
                  </Text>
                );
              })}
            </Stack>
          </Stack>
        </>
      )}

      {suggestedQuestions && suggestedQuestions.length > 0 && (
        <>
          <Divider my={6} borderColor={"teal.700"} />
          <Stack>
            <Text fontSize={"sm"} color="text.400" fontWeight={"semibold"}>
              Explore further
            </Text>

            <Stack>
              {suggestedQuestions.map((it, idx) => {
                return (
                  <AstrologerQuestionBtn
                    key={idx}
                    text={it}
                    color="teal.100"
                    fontSize={"sm"}
                  />
                );
              })}
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
}

const RandomText = `Vitae sipean ut liebro vneantis fbuiscas. Fusce coidnmetnum nunc ac nsi vuatlpue fniilrgra. Donec lcniia congue feliis in faiscbus. Pclesuentile hibatant morbi tsiqutrie scetneus et neuts et meislauda faems ac trupis egestas. Visitlbumu ante imusp pimris in fbusiics orci luctus et utcerxis psiurce cublia Curae; Dnoec velit nqeuue, actor sit amet auiqlam vel, uaollrmcepr sit amet lgulia. Pshlaelus hneriderit ronchus rius, nec sduolilictun meuts gadvria eget. Aqluiam erat vpoutalt. Ietgner nec odios.

In the ladns where sohrclca dteabe and phiasolopzihe, where ainnect scrolsl lay nesletd in garnrd laerbiirs, the pusriut of knlgoewde cnioetnus uinebatad. In every wittren word, in every icnsrebid lnie there leis the ecsnsee of agse psat. The inisght from vraneeble tkinhers, sedicrb mitiuolcseuly by cdlaleignht, caerirs froth the usetandrning of hmaun ecexnriepe. Each paragarph, a gtaaewy to the ahistsctee and ehtcial ermteexs dicssesud by Ciceor, fnids its echo in the huehsd claiordors of time.

Pesrnet vesmibtlium mloes tie lcaus. Aenean nomumy hieindrret mauiirs. Cum soicis naqoute pencitaubs et maigns dis ptartuiient mnotes, ncautser rudlciuus mus. Nuulla dui. Fusce fgeuiat mioaesuda oido. Mrboi nunc odio, givarda at, crusus nec, luctus a, lorem. Maursi feermnutm dcutim maang. Sed leoarte aluqiam leo. Ut teluls dloor, dapiubs eget, etlemneum vel, crusus eienfld, eilt.`;

function CTA(props: StackProps) {
  const navigate = useNavigate();

  return (
    <Stack
      spacing={3}
      alignItems={"center"}
      mx={"auto"}
      position={"absolute"}
      left={0}
      width={"full"}
      {...props}
    >
      <Text
        textAlign={"center"}
        fontWeight={"bold"}
        color={"purple.300"}
        fontSize={"lg"}
        mx={4}
      >
        You are seeing only a fragment of the full answer
      </Text>

      <Button
        colorScheme="purple"
        size={"lg"}
        borderRadius={"full"}
        mx="auto"
        onClick={() => {
          navigate(`/summary`);
        }}
      >
        🔓 Unlock Full Answer
        <Icon ml={2} as={FaArrowRight} fontSize={"sm"} />
      </Button>
    </Stack>
  );
}
