import { Text, TextProps } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useRootStore } from "stores/useRootStore";

export function Badge({ ...rest }: TextProps) {
  return (
    <Text
      py={"4px"}
      px={"14px"}
      backgroundColor="orange.300"
      color="white"
      fontWeight={"bold"}
      fontSize={"11px"}
      textTransform={"uppercase"}
      borderRadius={"5px"}
      {...rest}
    />
  );
}

export function Timer({
  timestamp = 1000 * 60 * 324,
  ...rest
}: TextProps & { timestamp?: number }) {
  const r = useRootStore();

  const [timeRemaining, setTimeRemaining] = useState(
    timestamp - (Date.now() - r.appLoadedAt),
  );

  useEffect(() => {
    const i = setInterval(() => {
      setTimeRemaining((v) => {
        const nextValue = v - 1000;
        if (nextValue < 0) {
          clearInterval(i);
          return 0;
        }
        return nextValue;
      });
    }, 1000);
    return () => clearInterval(i);
  }, []);

  const readableTime = formatTimestamp(timeRemaining);

  return (
    <Text fontSize={"md"} {...rest}>
      {readableTime.hours}h {readableTime.minutes}min {readableTime.seconds}s
    </Text>
  );
}

function formatTimestamp(timestamp: number): {
  hours: string;
  minutes: string;
  seconds: string;
} {
  const hours = Math.floor(timestamp / (60000 * 60));
  timestamp = timestamp - hours * 60000 * 60;

  const minutes = Math.floor(timestamp / 60000);
  timestamp = timestamp - minutes * 60000;

  const seconds = Math.floor(timestamp / 1000);

  return {
    hours: hours.toString().padStart(2, "0"),
    minutes: minutes.toString().padStart(2, "0"),
    seconds: seconds.toString().padStart(2, "0"),
  };
}
