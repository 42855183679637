import { Button, Icon, Input, Stack, useTheme } from "@chakra-ui/react";
import React from "react";
import { IoSparklesSharp } from "react-icons/io5";
import { useServices } from "services/useServices";

export function AstrologerForm({
  onSubmit,
}: {
  onSubmit: (question: string) => void;
}) {
  const theme = useTheme();
  const { tracking } = useServices();

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const [inputValue, setInputValue] = React.useState<string>("");
  const [isInputFocused, setIsInputFocused] = React.useState<boolean>(false);

  return (
    <Stack
      spacing={0}
      py={2}
      pl={3}
      pr={2}
      backgroundColor={"black"}
      border={
        isInputFocused
          ? "1px solid transparent"
          : `1px solid ${theme.colors.teal[200]}`
      }
      outline={isInputFocused ? "2px solid #8dd4ff" : undefined}
      borderRadius={"xl"}
      direction={"row"}
      alignItems={"center"}
    >
      <Input
        placeholder="Type in your question here..."
        value={inputValue}
        ref={inputRef}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        onFocus={() => {
          setIsInputFocused(true);
        }}
        onBlur={() => {
          setIsInputFocused(false);
        }}
        borderRadius={"none"}
        p={0}
        height={"25px"}
        border="0px solid transparent"
        color="white"
        _hover={{
          border: "0px solid transparent",
        }}
        backgroundColor="transparent"
        focusBorderColor="transparent"
        _placeholder={{
          color: "#77aaad",
        }}
      />

      <Button
        flexShrink={0}
        size={"sm"}
        type="submit"
        mx="auto"
        colorScheme="purple"
        bg="purple.500"
        color="purple.50"
        borderRadius={"lg"}
        onClick={() => {
          if (inputValue) {
            tracking.capture("question-btn-clicked", {
              question: inputValue,
            });

            onSubmit(inputValue);

            setInputValue("");
          } else {
            inputRef.current?.focus();
          }
        }}
      >
        <Icon as={IoSparklesSharp} mr={2} boxSize={4} color={"yellow.300"} />
        Ask
      </Button>
    </Stack>
  );
}
