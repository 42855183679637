import { Flex, FlexProps, Icon, Stack, Text } from "@chakra-ui/react";
import { capitalizeFirstLetter } from "lib/strings";
import { User } from "services/coreapi";

export function UserProfileWidget({
  user,
  ...rest
}: { user: User } & FlexProps) {
  function getName() {
    if (user.name === "" || user.name == null) {
      return capitalizeFirstLetter(user.zodiac_sign);
    } else {
      return `${user.name}, ${capitalizeFirstLetter(user.zodiac_sign)}`;
    }
  }

  return (
    <Flex alignItems={"center"} gap={2} color="teal.400" {...rest}>
      <Icon as={user.zodiac_sign_svg} fill="teal.400" boxSize={5} />

      <Stack spacing={0} alignItems={"flex-start"}>
        <Text fontSize={"2xs"} fontFamily={"monospace"}>
          {getName()}
        </Text>

        <Text fontSize={"2xs"} fontFamily={"monospace"}>
          {Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "numeric",
            year: "2-digit",
          }).format(new Date(user.dob_utc))}
        </Text>
      </Stack>
    </Flex>
  );
}
