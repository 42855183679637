export type CacheVersion = {
  cacheVersion: number;
};

export class Cache<T extends object> {
  constructor(
    private cacheVersion: number,
    private key: string,
  ) {}

  getCache(): T | undefined {
    const item = localStorage.getItem(this.key);
    if (!item) {
      return;
    }

    try {
      const resolved: T & CacheVersion = JSON.parse(item);
      if (resolved.cacheVersion === this.cacheVersion) {
        // @ts-expect-error expected
        delete resolved.cacheVersion;
        return resolved;
      }

      this.deleteCache();
    } catch {
      this.deleteCache();
    }
  }

  setCache(input: T): void {
    localStorage.setItem(
      this.key,
      JSON.stringify({
        ...input,
        cacheVersion: this.cacheVersion,
      }),
    );
  }

  deleteCache(): void {
    localStorage.removeItem(this.key);
  }
}
