import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import { loadStripe } from "@stripe/stripe-js";
import { ResolvedPricingPlan } from "data/subscriptions";
import React from "react";
import { trackPixelEvent } from "services/tracking";
import { useServices } from "services/useServices";
import { useRootStore } from "stores/useRootStore";

import { CartPreview } from "./CartPreview";
import { CheckoutForm } from "./CheckoutForm";
import { Loading } from "./Loading";
import { RequestType } from "./types";

const stripe = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

export function CheckoutView({ planID }: { planID: string }) {
  const { coreapi } = useServices();
  const { user, pricingPlans } = useRootStore();

  const [plan, setPlan] = React.useState<ResolvedPricingPlan | null>(null);
  const [paymentIntent, setPaymentIntent] = React.useState<RequestType>({
    state: "initial",
  });

  React.useEffect(() => {
    trackPixelEvent("InitiateCheckout");
  }, []);

  React.useEffect(() => {
    const plan = pricingPlans.find((it) => it.lookupID === planID);
    if (plan) {
      setPlan(plan);
    }
  }, [pricingPlans, planID]);

  // IMPORTANT: payment intent has to be created in this step in order to retrieve a clientSecret
  // without clientSecret, we cannot render <Elements />
  React.useEffect(() => {
    async function initPaymentIntent() {
      try {
        setPaymentIntent({ state: "loading" });

        if (!plan) {
          return;
        }

        const data = await createPaymentIntent(plan);
        // Empty client secret means that overall price of the cart is very close to 0.
        // In that case subscription becomes automatically activated.
        // GOOD FOR TESTING!
        if (!data.client_secret) {
          console.log("no client secret, payment has been paid");
          return;
        }

        setPaymentIntent({
          state: "ok",
          data: {
            ...data,
            clientSecret: data.client_secret,
          },
        });
      } catch (err) {
        console.error(err);
        setPaymentIntent({
          state: "error",
          error: String(err),
        });
      }
    }

    initPaymentIntent();
  }, [plan]);

  async function createPaymentIntent(plan: ResolvedPricingPlan) {
    if (planID === "1-month-plan") {
      return coreapi.createSubscription({
        userID: user.id,
        priceID: plan.priceID,
      });
    } else {
      return coreapi.createLifetimePayment({
        userID: user.id,
        priceID: plan.priceID,
      });
    }
  }

  if (!plan) {
    return <Text color="black">no plan found with id {planID}</Text>;
  }

  return (
    <Stack>
      <Heading textAlign={"center"} fontSize={"xl"} color={"black"} mb={3}>
        Order summary
      </Heading>

      {paymentIntent.state === "error" && (
        <Box color="black">
          <Text fontWeight={"semibold"}>Error</Text>
          <Text>{paymentIntent.error}</Text>
        </Box>
      )}

      <CartPreview plan={plan} />

      {paymentIntent.state === "loading" && (
        <Loading text="Loading secure payment form..." />
      )}

      {paymentIntent.state === "ok" && (
        <CheckoutForm
          plan={plan}
          stripe={stripe}
          clientSecret={paymentIntent.data.clientSecret}
        />
      )}
    </Stack>
  );
}
