import {
  Button,
  ButtonProps,
  Flex,
  Icon,
  Stack,
  Text,
  keyframes,
} from "@chakra-ui/react";
import { ResolvedPricingPlan } from "data/subscriptions";
import { Span } from "lib/components";
import { FaArrowRight } from "react-icons/fa";

import { Timer } from "../components";

export function LifetimePlan({
  plan,
  onSelect,
}: {
  plan: ResolvedPricingPlan;
  onSelect: () => void;
}) {
  return (
    <Stack
      bgGradient="linear(to-br, red.500, yellow.500)"
      p={1}
      borderRadius={"xl"}
      spacing={1}
    >
      <Stack
        border="3px dashed"
        borderColor={"orange.200"}
        borderRadius={"md"}
        p={2}
      >
        <Text fontSize={"md"}>
          <Text as="span" fontWeight={"bold"} textDecoration={"underline"}>
            Be one of the last 50 people
          </Text>{" "}
          that can still get a lifetime access. Due to high demand we are
          switching to subscription-only.
        </Text>

        <Stack
          backgroundColor={"red.700"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          fontWeight={"bold"}
          spacing={0}
        >
          <Text fontSize={"md"} fontWeight={"normal"}>
            Until subscription-only:
          </Text>
          <Flex width={"130px"}>
            <Timer />
          </Flex>
        </Stack>
      </Stack>

      <Flex
        flexDirection={"column"}
        gap={4}
        p={3}
        borderRadius={10}
        backgroundColor="white"
        width={"100%"}
        position="relative"
      >
        <Flex
          flexDirection={"row"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
          width={"100%"}
          gap={3}
        >
          <Flex flexDirection={"column"} alignItems={"flex-start"} gap={3}>
            <Text
              fontSize={"xl"}
              fontWeight={"bold"}
              color="black"
              lineHeight={"normal"}
            >
              Lifetime Access
              <br /> + FREE Gifts 🎁
            </Text>
          </Flex>

          <Flex flexDirection={"column"} alignItems={"flex-end"}>
            <Text
              color="gray.900"
              textDecoration={"line-through"}
              lineHeight={"normal"}
              fontSize={"xl"}
            >
              ${plan.unitAmountBefore.toFixed(2)}
            </Text>
            <Text
              fontSize={"xl"}
              fontWeight={"bold"}
              lineHeight={"normal"}
              color="red.500"
            >
              ${plan.unitAmountNow.toFixed(2)}
            </Text>
          </Flex>
        </Flex>

        <Stack spacing={0} fontSize={"sm"} color="gray.900">
          <Text>• Pay once, use forever</Text>
          <Text>• Start receiving answers in the next 2 minutes!</Text>
          <Text>
            • 🎁 <Span fontWeight={"bold"}>FREE</Span> Natal chart analysis ($89
            worth)
          </Text>
          <Text>
            • 🎁 <Span fontWeight={"bold"}>FREE</Span> Relationship report ($59
            worth)
          </Text>
          <Text>
            • 🎁 <Span fontWeight={"bold"}>FREE</Span> Intro to astrology ($18
            worth)
          </Text>
        </Stack>

        <Text
          px={2}
          width={"100%"}
          color="red.500"
          fontWeight={"bold"}
          fontSize={"md"}
        >
          We have 7 lifetime offers left
        </Text>

        <CTAButton onClick={() => onSelect()} textTransform={"uppercase"}>
          <Stack spacing={1} alignItems={"center"}>
            <Text fontWeight={"bold"}>Choose Lifetime Access Now </Text>
            <Text fontSize={"sm"} opacity={0.8}>
              (Save ${(plan.unitAmountBefore - plan.unitAmountNow).toFixed(0)}{" "}
              Today!)
              <Icon ml={2} as={FaArrowRight} fontSize={"sm"} />
            </Text>
          </Stack>
        </CTAButton>
      </Flex>
    </Stack>
  );
}

function CTAButton(props: ButtonProps) {
  const ANIMATION = keyframes`
  0% { transform: rotate(0deg); box-shadow: 0 0 0 0px orange; }
  15% { transform: rotate(3deg) }
  35% { transform: rotate(-3deg) }
  45% { transform: rotate(0deg) }
  100% { transform: rotate(0deg); box-shadow: 0 0 0 15px rgba(0, 0, 0, 0); }; }
`;

  return (
    <Button
      py={8}
      fontSize={"md"}
      variant={"solid"}
      colorScheme="orange"
      width={"full"}
      borderRadius={"full"}
      border={"4px solid orange"}
      animation={`${ANIMATION} 2s ease-in-out infinite`}
      {...props}
    />
  );
}
