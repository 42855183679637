import React from "react";

import {
  PermissionProviderCtx,
  PermissionProviderType,
} from "./PermissionProvider";

export function usePermissions(): PermissionProviderType {
  const ctx = React.useContext(PermissionProviderCtx);
  if (!ctx) {
    throw new Error(
      "usePermissionProvider has to be used within PermissionProvider",
    );
  }

  return ctx;
}
