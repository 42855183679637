import { Progress, Stack, Text } from "@chakra-ui/react";

export function Loading({ text = "Loading..." }: { text?: string }) {
  return (
    <Stack mt={2}>
      <Text color="gray.700" fontSize={"sm"} textAlign={"center"}>
        {text}
      </Text>
      <Progress size="lg" isIndeterminate />
    </Stack>
  );
}
