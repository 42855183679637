import {
  Box,
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import Logo from "assets/intuvist-logo-white-h.png";
import { APP_CONFIG } from "data/subscriptions";
import { isEmailValid } from "lib/strings";
import React from "react";
import { Footer } from "src/layouts/Footer";
import { useAuthStore } from "stores/useAuthStore";

export function LoginPage() {
  const auth = useAuthStore();
  const [value, setValue] = React.useState<string>("");
  const [isValid, setIsValid] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setValue(e.target.value);
    setIsValid(true);
    setError(null);
  }

  async function login() {
    try {
      const isValid = isEmailValid(value);
      if (!isValid) {
        setIsValid(false);
        return;
      }

      setIsValid(true);
      setError(null);
      setIsLoading(true);

      await auth.login(value);
    } catch (err) {
      console.error(err);
      setError("User doesn't exist");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box width="full">
      <Container my={10}>
        <Stack
          as="form"
          onSubmit={(e) => {
            e.preventDefault();
            login();
          }}
        >
          <Image
            src={Logo}
            alt={`${APP_CONFIG.name} logo`}
            width={"120px"}
            mx="auto"
          />

          <Text
            fontSize={"4xl"}
            fontWeight={"bold"}
            textAlign={"center"}
            mb={6}
          >
            Welcome!
          </Text>

          <FormControl isInvalid={!isValid}>
            <FormLabel textAlign={"center"}>
              Enter your email to login
            </FormLabel>

            <Input
              type="email"
              placeholder="Email address"
              value={value}
              onChange={handleInputChange}
            />

            {!isValid && (
              <FormErrorMessage>Invalid email address</FormErrorMessage>
            )}

            <Button
              type="submit"
              width={"100%"}
              mt={3}
              colorScheme="purple"
              isDisabled={isLoading}
            >
              Login
              {isLoading && <Spinner color="white" size={"sm"} ml={2} />}
            </Button>

            {error && (
              <Text mt={4} color="red.500" fontSize={"sm"}>
                {error}
              </Text>
            )}
          </FormControl>
        </Stack>
      </Container>

      <Footer />
    </Box>
  );
}
