import { Stack, Text } from "@chakra-ui/react";
import gift1 from "assets/gift-1.jpg";
import gift3 from "assets/gift-3.jpg";
import gift4 from "assets/gift-4.jpg";
import { Span } from "lib/components";

import { FreeGiftItem } from "./FreeGiftItem";

export function FreeGiftsSection() {
  return (
    <Stack mt={8}>
      <Text
        textAlign={"center"}
        fontSize={"2xl"}
        fontWeight={"semibold"}
        lineHeight={1.3}
        color={"white"}
      >
        Unlock Now And{" "}
        <Text as="span" color={"orange.300"}>
          Claim 3 Gifts Worth $169 For FREE
        </Text>{" "}
        👇
      </Text>

      <Stack
        spacing={5}
        borderRadius={"lg"}
        border="3px dashed"
        borderColor={"orange.400"}
        p={3}
        py={4}
        mt={4}
      >
        <Text
          textAlign={"center"}
          fontSize={"lg"}
          lineHeight={1.3}
          color="#c9c4dd"
        >
          (These Astrological Gifts Will Be Uniquely{" "}
          <Span textDecoration={"underline"}>Personalized Just For You</Span>)
        </Text>

        <Stack spacing={8}>
          {/* <FreeGiftItem
            img={gift2}
            price={79.99}
            title="Astrological Forecast for the Next 6 Months of 2024"
            subtitle="Navigate the year 2024 with confidence, gain valuable insights into key opportunities and challenges in your relationships, career, and personal growth. Do not miss any life opportunities. Pdf"
          /> */}
          <FreeGiftItem
            img={gift3}
            price={59.99}
            title="Your Love & Relationships Compatibility Report"
            subtitle="Explore the potential of your future relationships with a personalized compatibility report. Understand the strengths and challenges with a prospective partner, helping you make informed and confident decisions in your romantic life. E-book"
          />

          <FreeGiftItem
            img={gift1}
            price={89.99}
            title="50+ Pages In-Depth Natal Chart Reading"
            subtitle="Get profound insights into your personality, strengths, and life path, discover personalized guidance on relationships, career, and personal growth, tailored uniquely to your birth details. E-book"
          />

          <FreeGiftItem
            img={gift4}
            price={18.99}
            title="Intro to Astrology"
            subtitle="Learn the basics of astrological signs, houses, and planets, and quickly gain the knowledge you need to interpret charts and forecasts with confidence. Pdf"
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
