import { Icon, Stack, StackProps, Text } from "@chakra-ui/react";
import { IoSparklesSharp } from "react-icons/io5";
import { useNavigate } from "react-router";
import { useServices } from "services/useServices";

export function AstrologerQuestionBtn({
  text,
  theme,
  ...rest
}: {
  text: string;
  theme?: {
    emoji: string;
    title: string;
  };
} & StackProps) {
  const { tracking } = useServices();
  const navigate = useNavigate();

  return (
    <Stack
      as="button"
      role="group"
      display={"inline-flex"}
      p={2}
      borderRadius={"xl"}
      whiteSpace="normal"
      alignItems={"flex-start"}
      bg="#805ad529"
      onClick={() => {
        tracking.capture("suggested-question-btn-clicked", { question: text });
        navigate(`/astrologer/new-instant-reading?question=${text}`);
      }}
      direction={"column"}
      spacing={1}
      {...rest}
    >
      <Stack direction={"row"} alignItems={"center"} gap={1}>
        <Stack
          spacing={1}
          px={2}
          direction={"row"}
          flexShrink={0}
          height={"25px"}
          justifyContent={"center"}
          alignItems={"center"}
          bg="purple.500"
          _groupHover={{
            bg: "purple.500",
          }}
          transition={"ease-in 100ms"}
          borderRadius={"lg"}
          flexGrow={0}
          position={"relative"}
        >
          <Icon as={IoSparklesSharp} boxSize={3} color={"yellow.300"} />

          <Text fontSize={"xs"} fontWeight={"semibold"} color="purple.50">
            Ask
          </Text>
        </Stack>

        {theme && (
          <Stack
            spacing={1}
            px={2}
            direction={"row"}
            flexShrink={0}
            height={"25px"}
            justifyContent={"center"}
            alignItems={"center"}
            bg="purple.800"
            _groupHover={{
              bg: "purple.800",
            }}
            transition={"ease-in 100ms"}
            borderRadius={"lg"}
            flexGrow={0}
            position={"relative"}
          >
            <Text fontSize={"xs"}>{theme.emoji}</Text>

            <Text fontSize={"xs"} fontWeight={"semibold"} color="purple.50">
              {theme.title}
            </Text>
          </Stack>
        )}
      </Stack>

      <Text
        color="inherit"
        textAlign={"left"}
        fontWeight={"semibold"}
        _groupHover={{
          textDecoration: "underline",
        }}
        mr={2}
      >
        {text}
      </Text>
    </Stack>
  );
}
