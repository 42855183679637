import { Button, Icon, Stack, Text } from "@chakra-ui/react";
import { ResourceRenderer } from "lib/components";
import { IoSparklesSharp } from "react-icons/io5";
import { useNavigate } from "react-router";
import { useReadingRepo } from "repositories/useReadingRepo";
import { SecondaryTopNav } from "src/layouts/SecondaryTopNav";
import { usePermissions } from "stores/usePermissionProvider";

import { ReadingListItem } from "./InstantReadingListItem";

export function InstantReadingsPage() {
  const navigate = useNavigate();
  const readingRepo = useReadingRepo();
  const permissions = usePermissions();

  return (
    <Stack>
      <SecondaryTopNav />

      <ResourceRenderer
        resource={readingRepo.instantReadings}
        fallback="Unfortunatelly we couldn't load your readings. Please try again later!"
      >
        {({ data }) => {
          return (
            <Stack>
              {data.length === 0 ? (
                <Stack spacing={4}>
                  <Text my={4} textAlign={"center"} color={"fallbackText"}>
                    You don't have any readings yet. <br /> Get your first one
                    below{" "}
                    <Icon
                      as={IoSparklesSharp}
                      boxSize={4}
                      color={"fallbackText"}
                    />
                  </Text>

                  <Button
                    colorScheme="purple"
                    onClick={() => navigate("/astrologer")}
                    mx="auto"
                    borderRadius={"full"}
                  >
                    Get a reading
                  </Button>
                </Stack>
              ) : (
                <Stack alignItems={"flex-start"} spacing={3}>
                  {data.map((it) => (
                    <ReadingListItem
                      key={it.id}
                      reading={it}
                      isLocked={!permissions.hasAccessToReading(it)}
                    />
                  ))}
                </Stack>
              )}
            </Stack>
          );
        }}
      </ResourceRenderer>
    </Stack>
  );
}
