import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { loadGoogleTagManager } from "services/googleads";
import { initPixel } from "services/pixel";
import { trackPixelPageView } from "services/tracking";
import { isProdMode } from "utils/isProdMode";

import { router } from "./router";

if (isProdMode()) {
  initPixel(import.meta.env.VITE_FB_PIXEL_ID);
  trackPixelPageView();
  loadGoogleTagManager(import.meta.env.VITE_GOOGLE_ADS_TAG);
}

Sentry.init({
  dsn: "https://ee10ee3f2db4bcfc23c7a41219e396ae@o4505752861212672.ingest.us.sentry.io/4507214044266496",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  debug: false,
  enabled: isProdMode(),
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
