import { Button, ButtonProps, Flex, Icon, Stack, Text } from "@chakra-ui/react";
import { ResolvedPricingPlan } from "data/subscriptions";
import { FaArrowRight } from "react-icons/fa";

export function MonthlyPlan({
  plan,
  onSelect,
}: {
  plan: ResolvedPricingPlan;
  onSelect: () => void;
}) {
  return (
    <Flex
      flexDirection={"column"}
      gap={3}
      p={3}
      borderRadius={10}
      backgroundColor="white"
      width={"100%"}
      position="relative"
    >
      <Flex
        flexDirection={"row"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        width={"100%"}
        gap={3}
        color="black"
      >
        <Flex flexDirection={"column"} alignItems={"flex-start"} gap={1}>
          <Text fontSize={"xl"} fontWeight={"bold"} lineHeight={"normal"}>
            1-Month Plan
          </Text>

          <Stack spacing={0} fontSize={"sm"} color="gray.900">
            <Text>• Only ${(plan.unitAmountNow / 30).toFixed(2)} / day</Text>
            <Text>• Cancel anytime</Text>
          </Stack>
        </Flex>

        <Flex flexDirection={"column"} alignItems={"flex-end"}>
          <Text
            fontSize={"xl"}
            fontWeight={"bold"}
            lineHeight={"normal"}
            color="gray.900"
          >
            ${plan.unitAmountNow.toFixed(2)}
          </Text>
        </Flex>
      </Flex>

      <CTAButton onClick={() => onSelect()} textTransform={"uppercase"}>
        Choose Now
        <Icon ml={2} as={FaArrowRight} fontSize={"sm"} />
      </CTAButton>
    </Flex>
  );
}

function CTAButton(props: ButtonProps) {
  return (
    <Button
      py={3}
      size={"lg"}
      fontSize={"md"}
      variant={"solid"}
      colorScheme="purple"
      width={"full"}
      borderRadius={"full"}
      border={"4px solid #aa98d3"}
      {...props}
    />
  );
}
