import { Icon, Stack, Text } from "@chakra-ui/react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

export function TermsAgreement() {
  return (
    <Stack color="black" direction={"row"} gap={1} alignItems={"flex-start"}>
      <Icon as={AiOutlineExclamationCircle} boxSize={3} mt={1} />

      <Text fontSize={"xs"}>
        By choosing a payment method, you agree to the{" "}
        <Link
          to={"https://intuvist.com/terms-and-conditions"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Text
            color="blue.500"
            cursor={"pointer"}
            textDecoration="underline"
            as="span"
          >
            T&Cs
          </Text>
        </Link>{" "}
        and{" "}
        <Link
          to={"https://intuvist.com/privacy-policy"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Text
            color="blue.500"
            cursor={"pointer"}
            textDecoration="underline"
            as="span"
          >
            Privacy Policy
          </Text>
        </Link>
      </Text>
    </Stack>
  );
}
