import { Container, Stack } from "@chakra-ui/react";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import { useServices } from "services/useServices";

import { AstrologerComment } from "./AstrologerComment";
import { ComparisonTable } from "./ComparisonTable";
import { DisclaimerSection } from "./DisclaimerSection";
import { FreeGiftsSection } from "./FreeGiftsSection";
import { HeroSection } from "./HeroSection";
import { MediaSection } from "./MediaSection";
import { PricingSection } from "./PricingSection";
import { TestimonialSection } from "./TestimonialSection";

export function SummaryPage() {
  const { tracking } = useServices();
  const navigate = useNavigate();
  const location = useLocation();

  function handleSectionCTAClick(sectionID: string) {
    tracking.capture("summary-page-cta-click", {
      sectionID,
    });

    navigate(`${location.pathname}#first-pricing-section`);
  }

  function handleCheckoutCTAClick(sectionID: string, planID: string) {
    tracking.capture("summary-page-cta-click", {
      sectionID,
    });

    tracking.capture("checkout_btn_click", {
      sectionID,
      planID,
    });

    navigate(`${location.pathname}?checkout=true&planID=${planID}`);
  }

  return (
    <Container py={10}>
      <Stack spacing={0}>
        <HeroSection
          sectionID="hero-section"
          onCTAClick={handleSectionCTAClick}
        />

        <FreeGiftsSection />

        <PricingSection
          sectionID="first-pricing-section"
          onCTAClick={handleCheckoutCTAClick}
        />

        <MediaSection />

        <TestimonialSection
          sectionID="testimonials-section"
          onCTAClick={handleSectionCTAClick}
        />

        <ComparisonTable
          sectionID="comparison-section"
          onCTAClick={handleSectionCTAClick}
        />

        <AstrologerComment />

        <PricingSection
          sectionID="second-pricing-section"
          onCTAClick={handleCheckoutCTAClick}
        />

        <DisclaimerSection />
      </Stack>
    </Container>
  );
}
