import { Image, Stack, Text } from "@chakra-ui/react";
import SignatureIMG from "assets/astrologer-sophia-signature.png";
import AstrologerIMG from "assets/astrologer-sophia.jpg";

export function AstrologerComment() {
  return (
    <Stack spacing={10} mt={12}>
      <Text
        textAlign={"center"}
        fontSize={"2xl"}
        fontWeight={"semibold"}
        lineHeight={1.3}
        color={"white"}
      >
        Meet our astrological mastermind of Intuvist - Dr. Sophia Claire Hart
      </Text>

      <Image src={AstrologerIMG} maxWidth={"80%"} mx="auto" />

      <Text
        fontSize={"xl"}
        fontWeight={"semibold"}
        color="purple.200"
        lineHeight={1.3}
      >
        "Team has condensed my 26 years of astrological expertise into an app
        that provides hyper-accurate readings."
      </Text>

      <Stack>
        <Text>
          Hello, I’m Dr. Sophia Claire Hart, a professional astrologer with over
          26 years of experience in western astrology, numerology and
          spirituality.
          <br />
          <br />
          Throughout my career, I’ve had the privilege of handling over 13,000
          cases, assisting people on their paths of self-discovery and personal
          growth through astrology. I wanted to share my knowledge with more
          people than I could physically reach. Thanks to the modern day
          technologies, I’ve integrated my expertise into a digital astrologer
          app, providing reliable guidance and clarity in your daily life.
          <br />
          <br />I wholeheartedly recommend trying this as a companion helper
          tool. Whether you’re navigating life’s challenges, seeking a deeper
          understanding of yourself, or simply curious about what the stars have
          to say, this app is here to support you every step of the way.
        </Text>

        <Text my={5}>
          Warm regards,
          <br />
          Dr. Sophia Claire Hart"
        </Text>

        <Image width={"170px"} src={SignatureIMG} />
      </Stack>
    </Stack>
  );
}
