import { Aquarius } from "./Aquarius";
import { Aries } from "./Aries";
import { Cancer } from "./Cancer";
import { Capricorn } from "./Capricorn";
import { Gemini } from "./Gemini";
import { Leo } from "./Leo";
import { Libra } from "./Libra";
import { Pisces } from "./Pisces";
import { Sagittarius } from "./Sagittarius";
import { Scorpio } from "./Scorpio";
import { Taurus } from "./Taurus";
import { Virgo } from "./Virgo";

export * from "./Aquarius";
export * from "./Aries";
export * from "./Cancer";
export * from "./Capricorn";
export * from "./Gemini";
export * from "./Leo";
export * from "./Libra";
export * from "./Pisces";
export * from "./Sagittarius";
export * from "./Scorpio";
export * from "./Taurus";
export * from "./Virgo";

type ZodiacSign =
  | "aries"
  | "taurus"
  | "gemini"
  | "cancer"
  | "leo"
  | "virgo"
  | "libra"
  | "scorpio"
  | "sagittarius"
  | "capricorn"
  | "aquarius"
  | "pisces";

export type ZodiacSignSVG = typeof Aquarius;

export function getZodiacSignSVG(zodiacSign: ZodiacSign): ZodiacSignSVG {
  const map: Record<ZodiacSign, ZodiacSignSVG> = {
    aries: Aries,
    taurus: Taurus,
    gemini: Gemini,
    cancer: Cancer,
    leo: Leo,
    virgo: Virgo,
    libra: Libra,
    scorpio: Scorpio,
    sagittarius: Sagittarius,
    capricorn: Capricorn,
    aquarius: Aquarius,
    pisces: Pisces,
  };

  return map[zodiacSign];
}
