import { Text, TextProps } from "@chakra-ui/react";

export function AstrologerText(props: TextProps) {
  return (
    <Text
      fontSize={"md"}
      textAlign={"center"}
      color="teal.100"
      filter={"drop-shadow(0px 3px 4px #719b9d)"}
      {...props}
    />
  );
}
