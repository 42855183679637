import { CoreServerType, InferModel } from "@astropal/api-client";
import { ApiErrorCodeType, DomainErrorType } from "@astropal/api-client";
import { treaty } from "@elysiajs/eden";
import { Aquarius } from "lib/zodiacSigns";

export type { DomainErrorType };

export type ZodiacSign =
  | "aries"
  | "taurus"
  | "gemini"
  | "cancer"
  | "leo"
  | "virgo"
  | "libra"
  | "scorpio"
  | "sagittarius"
  | "capricorn"
  | "aquarius"
  | "pisces";

// remove this
const eden = treaty<CoreServerType>("");

export type UserServer = InferModel<typeof eden.getUserByID.get>;

export type User = {
  zodiac_sign_svg: typeof Aquarius;
} & UserServer;

export type InstantReading = InferModel<typeof eden.getQuestionAnswer.get>;

export type InstantReadingWithBalance = InferModel<
  typeof eden.answerQuestion.post
>;

export type DailyReading = InferModel<typeof eden.getDailyReading.get>;

export type NatalChartReading = InferModel<
  typeof eden.getNatalChartReading.get
>;

export type NatalChart = InferModel<typeof eden.getNatalChartByUserID.get>;

export type SuggestedQuestion = InferModel<
  typeof eden.suggestedQuestions.get
>["suggestedQuestions"][number];

export type ServerResponseError = {
  status: number;
  value:
    | string
    | {
        code: ApiErrorCodeType;
        type?: DomainErrorType;
        userMessage?: string;
      };
};

export type PricingPlan = InferModel<
  typeof eden.currentPricingPlans.get
>[number];

export type PaidTrialPricingPlan = InferModel<
  typeof eden.currentPaidTrialPricingPlan.get
>;
