import {
  Button,
  Divider,
  Grid,
  Icon,
  Stack,
  Text,
  keyframes,
} from "@chakra-ui/react";
import { getAstrologerOrDefault } from "lib/astrologers";
import {
  AstrologerAvatar,
  AstrologerContainer,
  AstrologerErrorFallback,
  AstrologerLoading,
  AstrologerQuestionBtn,
  AstrologerReading,
  AstrologerText,
  ResourceRenderer,
  useAstrologer,
} from "lib/components";
import { AudioPlayer } from "lib/components/Astrologer/AudioPlayer";
import { formatDateTime } from "lib/strings";
import React from "react";
import { FaPlay } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router";
import { useReadingRepo } from "repositories/useReadingRepo";
import { useServices } from "services/useServices";
import { useRootStore } from "stores/useRootStore";

export function OnboardingPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { ask, result } = useAstrologer();
  const { tracking } = useServices();
  const { user } = useRootStore();
  const repo = useReadingRepo();

  const [question, setQuestion] = React.useState<string | undefined>();
  const [arrayBuffer, setArrayBuffer] = React.useState<
    | { status: "idle" }
    | { status: "loading" }
    | { status: "ok"; data: ArrayBuffer }
    | { status: "error" }
  >({ status: "idle" });

  async function loadAudio(id: string, answer: string) {
    try {
      setArrayBuffer({ status: "loading" });

      const arrayBuffer = await repo.getReadingArrayBuffer(id, answer);

      setArrayBuffer({ status: "ok", data: arrayBuffer });
    } catch (err) {
      //TODO: handle
      console.error(err);

      setArrayBuffer({ status: "error" });
    }
  }

  const astrologer = getAstrologerOrDefault(user.astrologer_persona_id);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const question = params.get("question");

    if (question) {
      setQuestion(question);
      tracking.capture("question-asked", { question });
      ask(question);
    }
  }, [location]);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const question = params.get("onboardingQuestion");

    if (question) {
      setQuestion(question);
      navigate(location.pathname, { replace: true });
    }
  }, [location]);

  function renderContent() {
    if (result.status === "loading") {
      return (
        <AstrologerContainer>
          <AstrologerAvatar animationDuration={1000} />
          <AstrologerText>"{question}"</AstrologerText>
          <AstrologerLoading loadingState={result.state} />
        </AstrologerContainer>
      );
    }

    if (result.status === "error") {
      return (
        <AstrologerContainer>
          <AstrologerAvatar animationDuration={3000} />
          <AstrologerText>"{question}"</AstrologerText>
          <AstrologerErrorFallback />
        </AstrologerContainer>
      );
    }

    if (result.status === "error-inappropriate") {
      return (
        <AstrologerContainer>
          <AstrologerAvatar animationDuration={3000} />
          <AstrologerText>"{question}"</AstrologerText>
          <AstrologerReading
            askedAt={new Date().toISOString()}
            answer={result.data.answer}
            isLocked={false}
          />
        </AstrologerContainer>
      );
    }

    if (result.status === "ok") {
      return (
        <AstrologerContainer>
          <AstrologerAvatar animationDuration={3000} />

          <AstrologerText>
            "{result.data.original_question_text}"
          </AstrologerText>

          <ResourceRenderer
            resource={result}
            fallback="Unfortunatelly we could could not make a reading. Please try again later!"
          >
            {({ data }) => (
              <Stack alignItems={"center"} spacing={12} mb={10}>
                <Stack spacing={3} alignItems={"flex-start"} width={"100%"}>
                  <Text fontSize={"xs"} color="text.400">
                    {formatDateTime(new Date(data.asked_at))}
                  </Text>

                  {arrayBuffer.status !== "ok" ? (
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Button
                        size={"md"}
                        isLoading={arrayBuffer.status === "loading"}
                        colorScheme="teal"
                        onClick={() => {
                          if (arrayBuffer.status === "error") {
                            return;
                          }
                          loadAudio(data.id, data.answer_text);
                        }}
                      >
                        {arrayBuffer.status === "error" ? (
                          "Try again later"
                        ) : (
                          <Icon as={FaPlay} boxSize={3} />
                        )}
                      </Button>

                      <Text color={"teal.500"} fontSize={"sm"}>
                        (Audio answer)
                      </Text>
                    </Stack>
                  ) : (
                    <AudioPlayer
                      autoplay={true}
                      arrayBuffer={arrayBuffer.data}
                      isReadingLocked={false}
                    />
                  )}

                  <Text>{data.answer_text}</Text>

                  {data.determinant_aspects.length > 0 && (
                    <>
                      <Divider my={6} borderColor={"teal.700"} />

                      <Stack>
                        <Text
                          fontSize={"sm"}
                          color="text.400"
                          fontWeight={"semibold"}
                        >
                          Determining Aspects
                        </Text>

                        <Stack>
                          {data.determinant_aspects.map((it, idx) => {
                            return (
                              <Text key={idx} fontSize={"sm"}>
                                - {it}
                              </Text>
                            );
                          })}
                        </Stack>
                      </Stack>
                    </>
                  )}
                </Stack>

                <Button
                  size={"md"}
                  mx="auto"
                  colorScheme="purple"
                  bg="purple.500"
                  color="purple.50"
                  borderRadius={"100px"}
                  onClick={() => {
                    navigate("/astrologer", { replace: true });
                  }}
                >
                  Continue exploring
                </Button>
              </Stack>
            )}
          </ResourceRenderer>
        </AstrologerContainer>
      );
    }

    return (
      <AstrologerContainer>
        <AstrologerAvatar animationDuration={3000} />
        <AstrologerText mb={4}>
          <Text as="span" fontWeight={"bold"}>
            Hi, I'm {astrologer.name},
            <br /> your personal astrologer.
          </Text>{" "}
          <br />
          Let's answer your first question.
        </AstrologerText>

        {question && (
          <AstrologerQuestionBtn
            text={question}
            animation={`${PULSE_ANIMATION} 1.5s ease-in-out  infinite`}
            onClick={() => {
              navigate(`?question=${question}`, { replace: true });
            }}
          />
        )}
      </AstrologerContainer>
    );
  }

  return (
    <Grid gridTemplateRows={"auto 1fr"} height={"100%"}>
      <Stack height={"40px"} />

      {renderContent()}
    </Grid>
  );
}

const PULSE_ANIMATION = keyframes`
  0% { box-shadow: 0 0 0 0px #805ad5; }
  100% { box-shadow: 0 0 0 20px rgba(0, 0, 0, 0); }; }
`;
