import { Container, Grid } from "@chakra-ui/react";

import { Footer } from "./Footer.tsx";
import { TopNav } from "./TopNav.tsx";

export function MainLayout({ children }: React.PropsWithChildren) {
  return (
    <Grid minHeight={"100vh"} gridTemplateRows={"auto 1fr"}>
      <Container p={0}>
        <TopNav />
      </Container>

      <Container position={"relative"} px={5} mt={3} mb={3}>
        {children}
      </Container>

      <Container mb={4}>
        <Footer />
      </Container>
    </Grid>
  );
}
