import { Box, Button, Stack, Text } from "@chakra-ui/react";
import { APP_CONFIG } from "data/subscriptions";
import { useState } from "react";
import { useLocation } from "react-router";
import { useServices } from "services/useServices";

type Status = "initial" | "loading" | "done" | "error";

export function UnsubscribeAllEmails() {
  const { coreapi } = useServices();
  const loc = useLocation();
  const [status, setStatus] = useState<{ type: Status; error?: string }>({
    type: "initial",
  });

  function handleUnsubscribe() {
    const params = new URLSearchParams(loc.search);
    const userID = params.get("userID");
    if (!userID) {
      setStatus({
        type: "error",
        error: "`userID` not provided in the URL search param",
      });
      return;
    }

    coreapi
      .unsubscribeAllEmails(userID)
      .then((res) => {
        if (res.error) {
          setStatus({ type: "error", error: String(res.error) });
          return;
        }
        setStatus({ type: "done", error: undefined });
      })
      .catch((err: unknown) => {
        setStatus({ type: "error", error: String(err) });
      });
  }

  return (
    <Stack spacing={10} alignItems={"center"}>
      <Text
        textAlign={"left"}
        fontSize={"xl"}
        fontWeight={"semibold"}
        color="text.main"
      >
        Would you prefer to unsubscribe from all {APP_CONFIG.name} emails?
      </Text>

      <Button
        onClick={() => {
          handleUnsubscribe();
        }}
        isDisabled={status.type === "done"}
        isLoading={status.type === "loading"}
        colorScheme="teal"
      >
        Unsubscribe
      </Button>

      <Box>
        {status.type === "done" ? (
          <Stack>
            <Text color="green.500" fontWeight={"bold"}>
              Unsubscribed.
            </Text>
            <Text>
              You can always opt back in to receive daily emails from your
              profile page if you change your mind.
            </Text>
          </Stack>
        ) : status.type === "error" ? (
          <Text bg="red.100" p={4}>
            {status.error}
          </Text>
        ) : undefined}
      </Box>
    </Stack>
  );
}
