import { Stack } from "@chakra-ui/react";
import {
  AstrologerAvatar,
  AstrologerContainer,
  AstrologerReading,
  AstrologerText,
  ResourceRenderer,
} from "lib/components";
import React from "react";
import { Outlet, useParams } from "react-router";
import { useReadingRepo } from "repositories/useReadingRepo";
import { InstantReading } from "services/coreapi";
import { SecondaryTopNav } from "src/layouts/SecondaryTopNav";
import { Resource } from "src/types";
import { usePermissions } from "stores/usePermissionProvider";

export function InstantReadingPage() {
  const { instantReadingID } = useParams<{ instantReadingID: string }>();
  const readingRepo = useReadingRepo();
  const permissions = usePermissions();

  const [instantReading, setInstantReading] = React.useState<
    Resource<InstantReading>
  >({
    status: "initial",
  });

  React.useEffect(() => {
    async function load() {
      if (!instantReadingID) {
        return;
      }

      try {
        setInstantReading({ status: "loading" });

        const data = await readingRepo.getInstantReading(instantReadingID);

        setInstantReading({ status: "ok", data });
      } catch (err) {
        // TODO: handle properly
        setInstantReading({ status: "error" });
      }
    }

    load();
  }, [instantReadingID]);

  return (
    <Stack>
      <SecondaryTopNav />

      <AstrologerContainer>
        <AstrologerAvatar animationDuration={3000} />
        {instantReading.status === "ok" && (
          <AstrologerText>
            "{instantReading.data.original_question_text}"
          </AstrologerText>
        )}

        <ResourceRenderer
          resource={instantReading}
          fallback="Unfortunatelly we cannot find your reading. Please try again later!"
        >
          {({ data }) => (
            <AstrologerReading
              askedAt={data.asked_at}
              answer={data.answer_text}
              suggestedQuestions={data.suggested_follow_up_questions}
              determinantAspects={data.determinant_aspects}
              isLocked={!permissions.hasAccessToReading(data)}
            />
          )}
        </ResourceRenderer>
      </AstrologerContainer>

      <Outlet />
    </Stack>
  );
}
