import { SuggestedQuestion } from "services/coreapi";

export const SUGGESTED_QUESTIONS: SuggestedQuestion[] = [
  {
    userId: null,
    id: "1",
    theme: "personality",
    text: "Am I destined for fame?",
  },
  {
    userId: null,
    id: "12",
    theme: "wellbeing",
    text: "What's the cure for healing my emotional wounds?",
  },
  {
    userId: null,
    id: "21",
    theme: "relationships",
    text: "Will I ever fall in love again?",
  },
  {
    userId: null,
    id: "3",
    theme: "career",
    text: "What's my ultimate career path?",
  },
  {
    userId: null,
    id: "4",
    theme: "relationships",
    text: "Why am I alone?",
  },
  {
    userId: null,
    id: "5",
    theme: "personality",
    text: "What's my superpower that sets me apart?",
  },
  {
    userId: null,
    id: "18",
    theme: "personality",
    text: "What am i meant to do before i die?",
  },
  {
    userId: null,
    id: "13",
    theme: "personality",
    text: "When will I finally break free from toxic patterns?",
  },
  {
    userId: null,
    id: "19",
    theme: "personality",
    text: "What are my worst traits?",
  },
  {
    userId: null,
    id: "7",
    theme: "personality",
    text: "Is there a dark and mysterious side I'm unaware of?",
  },
  {
    userId: null,
    id: "20",
    theme: "relationships",
    text: "Who is my soulmate?",
  },
  {
    userId: null,
    id: "9",
    theme: "personality",
    text: "Are there royal markers in my birth chart?",
  },
  {
    userId: null,
    id: "10",
    theme: "relationships",
    text: "Do the stars reveal a deep sexual fantasy I've been suppressing?",
  },
  {
    userId: null,
    id: "11",
    theme: "personality",
    text: "Am I fated to experience a spiritual awakening?",
  },
];
