import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Grid,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
} from "@chakra-ui/react";
import { UserProfileWidget } from "lib/components";
import React from "react";
import { FaUser } from "react-icons/fa";
import { FaListUl } from "react-icons/fa6";
import { GoHomeFill } from "react-icons/go";
import { IoMdExit } from "react-icons/io";
import { IoSparklesSharp } from "react-icons/io5";
import { MdFaceUnlock } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useAuthStore } from "stores/useAuthStore";
import { useRootStore } from "stores/useRootStore";

export function TopNav() {
  const auth = useAuthStore();
  const navigate = useNavigate();
  const store = useRootStore();

  return (
    <Grid
      p={3}
      alignItems={"center"}
      gridTemplateColumns={"1fr 2fr 1fr"}
      gap={2}
      backgroundColor={"cosmos"}
    >
      <Link to={"/astrologer"}>
        <IconButton
          aria-label="Home"
          icon={<Icon as={GoHomeFill} />}
          variant={"text"}
          color={"white"}
          border={"1px solid"}
          borderColor={"whiteAlpha.300"}
          _hover={{
            backgroundColor: "whiteAlpha.400",
          }}
          _active={{
            backgroundColor: "whiteAlpha.400",
          }}
        />
      </Link>

      <UserProfileWidget user={store.user} mx="auto" />

      <Menu>
        <MenuButton
          ml="auto"
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon color="white" />}
          variant="ghost"
          border={"1px solid"}
          borderColor={"whiteAlpha.300"}
          _hover={{
            backgroundColor: "whiteAlpha.400",
          }}
          _active={{
            backgroundColor: "whiteAlpha.400",
          }}
        />

        <MenuList
          color="gray.600"
          border={"none"}
          boxShadow={"lg"}
          zIndex={100}
        >
          <MenuItemStyled onClick={() => navigate("/astrologer")}>
            <IconStyled as={IoSparklesSharp} />
            Astrologer
          </MenuItemStyled>

          <MenuItem onClick={() => navigate("/astrologer/instant-readings")}>
            <IconStyled as={FaListUl} />
            Your Astrological Readings
          </MenuItem>

          <MenuItemStyled onClick={() => navigate("/face-reading")}>
            <IconStyled as={MdFaceUnlock} />
            Your Face Reading
          </MenuItemStyled>

          <MenuItem onClick={() => navigate("/profile")}>
            <IconStyled as={FaUser} />
            Profile
          </MenuItem>

          <Box width={"full"} height={"1px"} backgroundColor={"gray.200"} />

          <MenuItem onClick={() => auth.logout()}>
            <IconStyled as={IoMdExit} /> Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </Grid>
  );
}

function IconStyled(props: React.ComponentProps<typeof Icon>) {
  return <Icon mr={3} boxSize={3} color="gray.600" {...props} />;
}

function MenuItemStyled(props: MenuItemProps) {
  return (
    <MenuItem
      _hover={{ backgroundColor: "bg.100" }}
      _active={{ backgroundColor: "bg.100" }}
      _focus={{ backgroundColor: "bg.100" }}
      {...props}
    />
  );
}
