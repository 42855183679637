import { Button, Icon, Stack } from "@chakra-ui/react";
import { FaAngleLeft } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router";

export function SecondaryTopNav() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Stack direction={"row"} gap={2} alignItems={"center"} height={"40px"}>
      {location.pathname !== "/" && (
        <Button
          size={"md"}
          variant="ghost"
          color="text.main"
          _hover={{
            backgroundColor: "blackAlpha.200",
          }}
          _active={{
            backgroundColor: "blackAlpha.200",
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <Icon as={FaAngleLeft} />
        </Button>
      )}
    </Stack>
  );
}
