import posthog from "posthog-js";
import { isProdMode } from "utils/isProdMode";

import pkgjson from "../../package.json";

const POSTHOG_KEY = "phc_zq8o1MJETg7eWOWcLI224iei1EhnhIbX0AM0EVLerdt";
const POSTHOG_HOST = "https://us.posthog.com";

export class Tracking {
  constructor() {
    posthog.init(POSTHOG_KEY, {
      api_host: POSTHOG_HOST,
      capture_pageview: false,
      autocapture: false,
      disable_session_recording: !isProdMode(),
      debug: !isProdMode(),
    });

    posthog.register({
      webapp_version: pkgjson.version,
    });

    if (!isProdMode()) {
      posthog.opt_out_capturing();
    }
  }

  get tracker() {
    return posthog;
  }
}

export function trackPixelEvent(
  event: string,
  properties?: Record<string, any>,
) {
  if (typeof window === "undefined") {
    return;
  }
  if (!(window as any).fbq) {
    return;
  }
  (window as any).fbq("track", event, properties);
}

export function trackPixelPageView() {
  trackPixelEvent("PageView");
}

/**
 * @param value - for $5 usd pass 5
 */
export function gaTrackPaidTrialPurchaseConversion(input: {
  value: number;
  currency: string;
}) {
  if (!(window as any).gtag) {
    console.error("google tag not loaded before the app is loaded");
    return;
  }

  (window as any).gtag("event", "conversion", {
    send_to: `${import.meta.env.VITE_GOOGLE_ADS_TAG}/ppfXCKaJlcAZEMrCmPw9`,
    value: input.value,
    currency: input.currency.toUpperCase(),
    transaction_id: "",
  });
}
