import React from "react";

import {
  NoticicationStoreCtx,
  NotificationStoreType,
} from "./NotificationStore";

export function useNotificationStore(): NotificationStoreType {
  const ctx = React.useContext(NoticicationStoreCtx);
  if (!ctx) {
    throw new Error(
      "useNotificationStore has to be used within NotificationStore",
    );
  }

  return ctx;
}
