import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Flex,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  Elements,
  ExpressCheckoutElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { type Stripe } from "@stripe/stripe-js";
import { ResolvedPricingPlan } from "data/subscriptions";
import { createSuccessCheckoutURL } from "pages/SuccessCheckoutPage";
import React from "react";
import { AiOutlineSafety } from "react-icons/ai";
import { trackPixelEvent } from "services/tracking";

import { Loading } from "./Loading";
import { TermsAgreement } from "./TermsAgreement";
import { RequestType } from "./types";

export function CheckoutForm({
  plan,
  stripe,
  clientSecret,
}: {
  plan: ResolvedPricingPlan;
  stripe: Promise<Stripe | null>;
  clientSecret: string;
}) {
  return (
    <Provider stripe={stripe} clientSecret={clientSecret}>
      <Form plan={plan} />
    </Provider>
  );
}

function Provider({
  stripe,
  clientSecret,
  children,
}: {
  stripe: Promise<Stripe | null>;
  clientSecret: string;
} & React.PropsWithChildren) {
  return (
    <Elements
      stripe={stripe}
      options={{
        clientSecret: clientSecret,
        appearance: {
          theme: "stripe",
          variables: {
            tabLogoColor: "green",
            tabLogoSelectedColor: "red",
          },
        },
      }}
    >
      {children}
    </Elements>
  );
}

function Form({ plan }: { plan: ResolvedPricingPlan }) {
  const stripe = useStripe();
  const elements = useElements();

  const [expressCheckoutReady, setExpressCheckoutReady] = React.useState(false);
  const [cardCheckoutReady, setCardCheckoutReady] = React.useState(false);
  const [payment, setPayment] = React.useState<RequestType>({
    state: "initial",
  });

  async function submitPayment(type: "express" | "card") {
    if (!stripe || !elements) {
      return;
    }

    setPayment({ state: "loading" });

    const redirectUrl = createSuccessCheckoutURL(
      type,
      plan.unit_amount,
      plan.currency,
      plan.lookupID,
    );

    trackPixelEvent("AddPaymentInfo");

    try {
      const res = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: redirectUrl.toString(),
        },
      });

      if (res.error) {
        setPayment({
          state: "error",
          error: res.error.message ?? "Your payment has been declined.",
        });

        return;
      }
    } catch (err) {
      setPayment({ state: "error", error: String(err) });
      throw err;
    }

    // Your customer will be redirected to your `return_url`. For some payment
    // methods like iDEAL, your customer will be redirected to an intermediate
    // site first to authorize the payment, then redirected to the `return_url`.
  }

  return (
    <>
      {(!cardCheckoutReady || !expressCheckoutReady) && (
        <Loading text="Loading secure payment form..." />
      )}

      <Stack
        pb={2}
        opacity={payment.state === "loading" ? 0.6 : undefined}
        display={cardCheckoutReady && expressCheckoutReady ? undefined : "none"}
      >
        <Stack pb={6}>
          {payment.state === "error" && (
            <Text
              color={"red.600"}
              mb={3}
              fontSize={"sm"}
              fontWeight={"semibold"}
              py={1}
              px={3}
              borderRadius={"lg"}
              backgroundColor={"#ffdad3"}
            >
              {payment.error} <br />
              Please try another card or method.
            </Text>
          )}

          <Box width={"full"}>
            <Text
              mb={4}
              color="black"
              fontSize={"sm"}
              textAlign={"center"}
              fontWeight={"semibold"}
            >
              Select a payment method
            </Text>

            <ExpressCheckoutElement
              options={{
                layout: { maxColumns: 1, maxRows: 5 },
              }}
              onReady={() => {
                setExpressCheckoutReady(true);
              }}
              onConfirm={() => {
                submitPayment("express");
              }}
            />

            <Box width={"full"} my={8} position={"relative"}>
              <Divider borderColor={"gray.300"} width={"full"} opacity={0.5} />
              <AbsoluteCenter
                bg="white"
                px="4"
                color="black"
                fontSize={"sm"}
                width={160}
                textAlign={"center"}
                fontWeight={"semibold"}
              >
                Or pay using card
              </AbsoluteCenter>
            </Box>

            <Box
              as="form"
              onSubmit={(e: React.FormEvent) => {
                e.preventDefault();
                submitPayment("card");
              }}
            >
              <PaymentElement
                options={{
                  readOnly: payment.state === "loading",
                  layout: {
                    type: "tabs",
                  },
                }}
                onReady={() => {
                  setCardCheckoutReady(true);
                }}
              />

              <Button
                mt={6}
                type="submit"
                isDisabled={payment.state === "loading" || !stripe || !elements}
                isLoading={payment.state === "loading"}
                colorScheme="twitter"
                width={"full"}
              >
                Pay Now with Card
              </Button>
            </Box>
          </Box>

          <Box textAlign={"center"}>
            <Text as="span" color="gray.500" fontSize={"sm"}>
              Safe checkout powered by{" "}
              <StripeLogo
                style={{ display: "inline", verticalAlign: "middle" }}
                height={19}
              />
            </Text>
          </Box>

          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent={"center"}
            gap={1}
          >
            <AiOutlineSafety size={18} color="green" />
            <Text color="gray.600" fontSize={"sm"}>
              Cancel anytime
            </Text>
          </Flex>
        </Stack>

        <TermsAgreement />
      </Stack>
    </>
  );
}

function StripeLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height={32}
      viewBox="0 0 940 427"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M938.054 225.563c0-65.834-31.878-117.779-92.816-117.779-61.195 0-98.225 51.945-98.225 117.266 0 77.39 43.69 116.469 106.45 116.469 30.598 0 53.738-6.945 71.214-16.707V273.38c-17.476 8.738-37.542 14.146-62.988 14.146-24.933 0-47.049-8.738-49.895-39.079h125.748c0-3.359.512-16.737.512-22.884zm-127.028-24.45c0-29.032 17.76-41.128 33.956-41.128 15.654 0 32.39 12.068 32.39 41.128h-66.346zm-163.29-93.329c-25.19 0-41.385 11.812-50.379 20.038l-3.359-15.939h-56.584v299.854l64.297-13.662.257-72.751c9.25 6.689 22.884 16.224 45.54 16.224 45.995 0 87.921-37.059 87.921-118.576-.285-74.572-42.722-115.188-87.693-115.188zm-15.427 177.18c-15.171 0-24.193-5.408-30.341-12.097l-.257-95.406c6.689-7.457 15.94-12.609 30.598-12.609 23.396 0 39.591 26.242 39.591 59.914 0 34.468-15.967 60.198-39.591 60.198zM513.506 78.724v-52.2l-64.553 13.633v52.457l64.553-13.89zm-64.553 33.415h64.553v224.997h-64.553V112.139zm-69.165 19.042l-4.098-19.042h-55.559v225.025h64.297V184.662c15.17-19.81 40.872-16.224 48.842-13.378v-59.145c-8.226-3.074-38.311-8.738-53.482 19.042zM251.223 56.352l-62.76 13.378-.257 205.955c0 38.055 28.549 66.09 66.603 66.09 21.091 0 36.518-3.842 44.999-8.481v-52.201c-8.225 3.33-48.841 15.171-48.841-22.884V166.93h48.841v-54.791h-48.841l.256-55.787zM77.401 177.461c0-10.019 8.226-13.89 21.831-13.89 19.554 0 44.231 5.92 63.785 16.451v-60.426a169.59 169.59 0 00-63.756-11.812c-52.23 0-86.925 27.239-86.925 72.779 0 70.957 97.712 59.658 97.712 90.227 0 11.84-10.275 15.711-24.677 15.711-21.347 0-48.614-8.738-70.217-20.578v61.194c23.908 10.304 48.102 14.658 70.217 14.658 53.481 0 90.227-26.47 90.227-72.522-.228-76.622-98.197-62.988-98.197-91.792z"
        fill="currentColor"
      />
    </svg>
  );
}
