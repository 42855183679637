import { useLazyRef } from "lib/reactHooks";
import posthog from "posthog-js";
import React from "react";
import { useLocation } from "react-router";
import { useNotificationStore } from "stores/useNotificationStore";

import { CoreAPI, CoreApiServerError, createCoreApiError } from "./coreapi";
import { Tracking } from "./tracking";

export type ServicesType = {
  coreapi: CoreAPI;
  tracking: Tracking["tracker"];
};

export const ServicesCtx = React.createContext<ServicesType | null>(null);

export function Services({ children }: React.PropsWithChildren) {
  const location = useLocation();
  const notificationStore = useNotificationStore();
  const tracking = useLazyRef(() => new Tracking().tracker);
  const coreapi = useLazyRef(
    () =>
      new CoreAPI({
        onError: (err) => {
          const clientError = createCoreApiError(err);

          if (clientError instanceof CoreApiServerError) {
            notificationStore.show({
              title: "Oops, something went wrong",
              status: "error",
              isClosable: true,
              duration: 3000,
            });
          }

          throw clientError;
        },
      }),
  );

  React.useEffect(() => {
    posthog.capture("$pageview");
  }, [location]);

  const value = React.useMemo(() => {
    return {
      coreapi: coreapi.current,
      tracking: tracking.current,
    };
  }, [coreapi, tracking]);

  return <ServicesCtx.Provider value={value}>{children}</ServicesCtx.Provider>;
}
