import { Spinner, Stack, Text } from "@chakra-ui/react";

export function Loading() {
  return (
    <Stack spacing={3} alignItems={"center"}>
      <Spinner size={"xl"} speed={"0.7s"} color="gray.400" />

      <Text color="gray.500">Just a moment...</Text>
    </Stack>
  );
}
