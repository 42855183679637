import { Button, Fade, Flex, Stack, Text } from "@chakra-ui/react";
import { getAstrologerOrDefault } from "lib/astrologers";
import { AstrologerAvatar, Span } from "lib/components";
import React from "react";
import { useNavigate } from "react-router";
import { useAuthStore } from "stores/useAuthStore";

export function WelcomePage() {
  const navigate = useNavigate();
  const auth = useAuthStore();

  const [hasLoaded, setHasLoaded] = React.useState<boolean>(false);
  const [showContent, setShowContent] = React.useState<boolean>(false);
  const [showButton, setShowButton] = React.useState<boolean>(false);

  React.useEffect(() => {
    const timeouts: number[] = [];

    timeouts.push(
      window.setTimeout(() => {
        setHasLoaded(true);
        setShowContent(true);
      }, 0),
    );

    timeouts.push(
      window.setTimeout(() => {
        setShowButton(true);
      }, 2000),
    );

    return () => {
      timeouts.forEach((it) => {
        clearTimeout(it);
      });
    };
  }, []);

  const astrologer = getAstrologerOrDefault(auth.user?.astrologer_persona_id);

  return (
    <Stack
      bg={
        hasLoaded
          ? "radial-gradient(circle, rgba(0,64,69,1) 0%, rgba(0,0,0,1) 100%);"
          : "white"
      }
      minHeight={"100vh"}
      py={2}
      transition="all 1s ease-in"
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Fade
        in={showContent}
        transition={{
          exit: { duration: 1 },
          enter: { duration: 2, delay: 1 },
        }}
        unmountOnExit
      >
        <Stack px={4} alignItems={"center"} spacing={0}>
          <Text
            fontSize={"2xl"}
            textAlign={"center"}
            fontWeight={"semibold"}
            color="purple.200"
          >
            Hello, I am {astrologer.name}
          </Text>
          <Text
            fontSize={"2xl"}
            textAlign={"center"}
            fontWeight={"semibold"}
            mb={8}
          >
            I will be your Astrologer
          </Text>

          <AstrologerAvatar animationDuration={3000} />

          <Flex
            alignItems={"center"}
            mt={8}
            bg="#007a662b"
            borderRadius={"full"}
            p={1}
            px={2}
            border={"1px solid"}
            borderColor={"teal.900"}
          >
            <Text fontSize={"sm"} color="teal.400">
              I am{" "}
              <Span fontWeight={"bold"} color="teal.200">
                100%
              </Span>{" "}
              trained on your{" "}
              <Span fontWeight={"bold"} color="teal.200">
                birth data
              </Span>
            </Text>
          </Flex>
          <Text fontSize={"xl"} fontWeight={"bold"} textAlign={"center"} mt={8}>
            I will provide you the most accurate readings to any of your
            questions
          </Text>
        </Stack>
      </Fade>

      <Fade
        in={showButton}
        transition={{
          enter: { duration: 1 },
        }}
      >
        <Stack px={4}>
          <Button
            mt={4}
            mx="auto"
            colorScheme="purple"
            onClick={() => {
              setShowButton(false);
              setShowContent(false);

              setTimeout(() => {
                navigate(`/astrologer`, {
                  replace: true,
                });
              }, 1500);
            }}
          >
            Ask your first question
          </Button>
        </Stack>
      </Fade>
    </Stack>
  );
}
