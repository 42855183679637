import { ResolvedPricingPlan, resolvePricingPlan } from "data/subscriptions";
import React, { useMemo } from "react";
import { User } from "services/coreapi";
import { useServices } from "services/useServices";

import { useAuthStore } from "./useAuthStore";

export type RootStoreType = {
  user: User;
  pricingPlans: ResolvedPricingPlan[];
  appLoadedAt: number;
  updateUser: (user: User) => void;
  currentPlanID: string | null;
  setCurrentPlanID: (planID: string) => void;
};

export const RootStoreCtx = React.createContext<RootStoreType | null>(null);

export function RootStore({ children }: React.PropsWithChildren) {
  const auth = useAuthStore();
  const appLoadedAt = useMemo(() => Date.now(), []);
  const { coreapi } = useServices();

  const [currentPlanID, setCurrentPlanID] = React.useState<string | null>(null);
  const [user, setUser] = React.useState<User>(auth.user!);
  const [pricingPlans, setPricingPlans] = React.useState<ResolvedPricingPlan[]>(
    [],
  );

  // This ensures that a new user data is pulled on a regular interval
  // with the latest state of subscription.
  React.useEffect(() => {
    const intervals: number[] = [];
    const ms = 300000; // 5min

    intervals.push(
      window.setInterval(async () => {
        const res = await coreapi.getUserByID(user.id);
        setUser(res);
        auth.setUser(res);
      }, ms),
    );

    return () => {
      intervals.forEach((it) => clearInterval(it));
    };
  }, []);

  React.useEffect(() => {
    coreapi.getCurrentPricingPlans().then((r) => {
      setPricingPlans(
        r.map((it, idx) => {
          const discount = idx === 0 ? 75 : idx === 1 ? 67 : 50;
          return resolvePricingPlan(it, discount);
        }) ?? [],
      );
    });
  }, []);

  const value = React.useMemo<RootStoreType>(() => {
    return {
      user,
      pricingPlans,
      appLoadedAt,
      updateUser: (user) => {
        setUser(user);
      },
      currentPlanID,
      setCurrentPlanID,
    };
  }, [user, pricingPlans, currentPlanID]);

  return (
    <RootStoreCtx.Provider value={value}>{children}</RootStoreCtx.Provider>
  );
}
