import { Button, Stack } from "@chakra-ui/react";
import {
  AstrologerAvatar,
  AstrologerContainer,
  AstrologerErrorFallback,
  AstrologerLoading,
  AstrologerReading,
  AstrologerText,
  useAstrologer,
} from "lib/components";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import { useServices } from "services/useServices";

export function NewInstantReadingPage() {
  const location = useLocation();
  const { tracking } = useServices();
  const navigate = useNavigate();
  const { ask, result } = useAstrologer();

  const [question, setQuestion] = React.useState<string | undefined>();

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const question = params.get("question");

    if (question) {
      submitQuestion(question);
    }
  }, [location]);

  React.useEffect(() => {
    if (result.status === "ok") {
      navigate(`/astrologer/instant-readings/${result.data.id}`, {
        replace: true,
      });
    }
  }, [result]);

  function submitQuestion(question: string) {
    tracking.capture("question-asked", { question });
    setQuestion(question);
    ask(question);
  }

  function renderContent() {
    if (result.status === "loading") {
      return (
        <AstrologerContainer>
          <AstrologerAvatar animationDuration={1000} />
          <AstrologerText>"{question}"</AstrologerText>
          <AstrologerLoading loadingState={result.state} />
        </AstrologerContainer>
      );
    }

    if (result.status === "error") {
      return (
        <AstrologerContainer>
          <AstrologerAvatar animationDuration={3000} />
          <AstrologerText>"{question}"</AstrologerText>
          <AstrologerErrorFallback />
        </AstrologerContainer>
      );
    }

    if (result.status === "error-inappropriate") {
      return (
        <AstrologerContainer>
          <AstrologerAvatar animationDuration={3000} />
          <AstrologerText>"{question}"</AstrologerText>
          <AstrologerReading
            askedAt={new Date().toISOString()}
            answer={result.data.answer}
            isLocked={false}
          />
          <Button
            colorScheme="purple"
            size={"lg"}
            borderRadius={"full"}
            mx="auto"
            onClick={() => {
              navigate(`/astrologer`);
            }}
          >
            Ask another question
          </Button>
        </AstrologerContainer>
      );
    }

    if (result.status === "ok") {
      return null;
    }

    if (result.status === "initial") {
      return null;
    }
  }

  return (
    <Stack>
      <Stack height={"40px"} />

      {renderContent()}
    </Stack>
  );
}
