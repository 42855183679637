import { SUGGESTED_QUESTIONS } from "data/suggestedQuestions";
import { getAstrologerOrDefault } from "lib/astrologers";
import React from "react";
import {
  InstantReading,
  InstantReadingWithBalance,
  SuggestedQuestion,
} from "services/coreapi";
import { useServices } from "services/useServices";
import { Resource } from "src/types";
import { useRootStore } from "stores/useRootStore";

export type ReadingRepoType = {
  getInstantReading(id: string): Promise<InstantReading>;
  createInstantReading(question: string): Promise<InstantReadingWithBalance>;
  instantReadings: Resource<InstantReading[]>;
  suggestedQuestions: Resource<SuggestedQuestion[]>;
  getReadingArrayBuffer(readingID: string, text: string): Promise<ArrayBuffer>;
};

export const ReadingRepoCtx = React.createContext<ReadingRepoType | null>(null);

export function ReadingRepo({ children }: React.PropsWithChildren) {
  const { coreapi } = useServices();
  const store = useRootStore();

  const [instantReadings, setInstantReadings] = React.useState<
    Resource<InstantReading[]>
  >({ status: "initial" });

  const [suggestedQuestions, setSuggestedQuestions] = React.useState<
    Resource<SuggestedQuestion[]>
  >({
    status: "initial",
  });

  const [readingArrayBuffers, setReadingArrayBuffers] = React.useState<
    Array<{
      readingID: string;
      arrayBuffer: ArrayBuffer;
    }>
  >([]);

  async function loadInstantReadings(): Promise<void> {
    try {
      setInstantReadings({ status: "loading" });

      const data = await coreapi.getAllInstantReadings(store.user.id);

      setInstantReadings({ status: "ok", data });
    } catch (err) {
      // TODO: handle properly
      setInstantReadings({ status: "error" });
    }
  }

  React.useEffect(() => {
    loadInstantReadings();
    setSuggestedQuestions({
      status: "ok",
      data: SUGGESTED_QUESTIONS,
    });
  }, []);

  const value = React.useMemo<ReadingRepoType>(() => {
    return {
      getInstantReading: async (id: string) => {
        let res: InstantReading | undefined;

        if (instantReadings.status == "ok") {
          res = instantReadings.data.find((it) => it.id === id);
        }

        if (!res) {
          return coreapi.getInstantReading(store.user.id, id);
        }

        return res;
      },

      getReadingArrayBuffer: async (readingID: string, text: string) => {
        const cache = readingArrayBuffers.find(
          (it) => it.readingID === readingID,
        );

        if (cache) {
          return cache.arrayBuffer;
        }

        const astrologer = getAstrologerOrDefault(
          store.user.astrologer_persona_id,
        );
        const arrayBuffer = await coreapi.getInstantReadingArrayBuffer(
          text,
          astrologer.gender,
        );

        setReadingArrayBuffers((state) => {
          return [...state, { readingID, arrayBuffer }];
        });

        return arrayBuffer;
      },

      createInstantReading: async (question: string) => {
        const res = await coreapi.createInstantReading(store.user.id, question);

        // Updates user credit balance
        store.updateUser({
          ...store.user,
          CreditBalance: {
            userID: store.user.id,
            balance: res.creditBalance.balance,
          },
        });

        // Updates reading list
        setInstantReadings((state) => {
          if (state.status !== "ok") {
            return state;
          }

          return {
            ...state,
            data: [res.answer, ...state.data],
          };
        });

        return res;
      },
      instantReadings,
      suggestedQuestions,
    };
  }, [instantReadings, suggestedQuestions, readingArrayBuffers]);

  return (
    <ReadingRepoCtx.Provider value={value}>{children}</ReadingRepoCtx.Provider>
  );
}
