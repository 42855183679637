import { Stack, Text, TextProps } from "@chakra-ui/react";
import { APP_CONFIG } from "data/subscriptions";
import { SecondaryTopNav } from "src/layouts/SecondaryTopNav";

function SectionTitle(props: TextProps) {
  return (
    <Text
      textAlign={"left"}
      fontSize={"md"}
      fontWeight={"bold"}
      color="text.main"
      {...props}
    />
  );
}

export function TermsAndConditionsPage() {
  return (
    <Stack spacing={8}>
      <SecondaryTopNav />

      <Stack spacing={3}>
        <SectionTitle>1. Introduction</SectionTitle>
        <Text>
          Welcome to the Terms and Conditions ("Terms") for {APP_CONFIG.name}.
          These Terms govern your access to and use of our website (
          {APP_CONFIG.url}) and the products and services offered through our
          e-commerce platform. By accessing our website and using our services,
          you agree to comply with and be bound by these Terms. Please read them
          carefully.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>2. Acceptance of Terms</SectionTitle>
        <Text>
          By using our website and services, you acknowledge that you have read,
          understood, and agreed to these Terms. If you do not agree with any
          part of these Terms, please do not use our website or services.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>3. Eligibility</SectionTitle>
        <Text>
          To use our services, you must be at least 18 years old or have the
          legal capacity to enter into contracts. By using our website, you
          represent and warrant that you meet these requirements.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>4. Account Registration</SectionTitle>
        <Text>
          To access certain features of our website or make purchases, you may
          be required to create an account. You are responsible for maintaining
          the confidentiality of your account information and agree to accept
          responsibility for all activities that occur under your account.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>5. Prohibited Activities</SectionTitle>
        <Text>
          You agree not to engage in any of the following prohibited activities:
        </Text>
        <Text>- Violating any applicable laws or regulations.</Text>
        <Text>
          - Infringing upon the rights of others, including intellectual
          property rights.
        </Text>
        <Text>
          - Attempting to interfere with the proper functioning of our website
          or services.
        </Text>
        <Text>
          - Uploading or distributing any harmful or malicious software.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>6. Product Information</SectionTitle>
        <Text>
          We strive to provide accurate product information, including
          descriptions, pricing, and availability. However, we do not warrant
          that product descriptions or other content on our website are
          accurate, complete, reliable, current, or error-free. In the event of
          inaccuracies, we reserve the right to correct them without prior
          notice.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>7. Orders and Payment</SectionTitle>
        <Text>
          By placing an order through our website, you agree to pay the
          specified price for the selected product or service. Payment
          processing is conducted through secure third-party payment processors.
          We do not store your payment information.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>8. Returns and Refunds</SectionTitle>
        <Text>
          Please review our Return and Refund Policy for information on product
          returns and refunds.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>9. Trademarks and Copyrights</SectionTitle>
        <Text>
          All content on our website, including text, graphics, logos, images,
          and software, is the property of {APP_CONFIG.name} or its licensors
          and is protected by intellectual property laws. You may not use our
          content without prior written consent.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>10. User-Generated Content</SectionTitle>
        <Text>
          By submitting content (e.g., reviews, comments, or suggestions) to our
          website, you grant {APP_CONFIG.name} a non-exclusive, royalty-free,
          perpetual, irrevocable, and fully sublicensable right to use,
          reproduce, modify, adapt, publish, translate, create derivative works
          from, distribute, and display such content worldwide.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>11. Privacy Policy</SectionTitle>
        <Text>
          Our Privacy Policy governs the collection, use, and disclosure of your
          personal information. By using our website and services, you consent
          to our privacy practices.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>12. Security</SectionTitle>
        <Text>
          We employ reasonable security measures to protect your data. However,
          we cannot guarantee the security of your information, and you
          acknowledge and accept the inherent risks associated with online
          transactions.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>13. Termination</SectionTitle>
        <Text>
          We reserve the right to terminate or suspend your access to our
          website and services, with or without notice, for any violation of
          these Terms or for any other reason.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>14. Disclaimer</SectionTitle>
        <Text>
          Our website and services are provided on an "as is" and "as available"
          basis without warranties of any kind, whether express or implied. We
          do not warrant that our website will be error-free or uninterrupted.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>15. Limitation of Liability</SectionTitle>
        <Text>
          In no event shall {APP_CONFIG.name} or its affiliates be liable for
          any indirect, incidental, special, consequential, or punitive damages,
          or for any loss of profits or revenues, whether incurred directly or
          indirectly, arising from your use of our website or services.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>16. Contact Information</SectionTitle>
        <Text>
          For any questions or concerns regarding these Terms and Conditions,
          please contact us at {APP_CONFIG.email}.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>17. Changes to the Terms</SectionTitle>
        <Text>
          {APP_CONFIG.name} reserves the right to update or modify these Terms
          and Conditions at any time without prior notice. It is your
          responsibility to review these Terms periodically for changes.
        </Text>
      </Stack>
    </Stack>
  );
}
