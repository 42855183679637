import { Icon, Image, Stack, Text } from "@chakra-ui/react";
import { getAstrologerOrDefault } from "lib/astrologers";
import { CTAButton, Span } from "lib/components";
import { FaArrowRight } from "react-icons/fa";
import { FaRegCircleCheck } from "react-icons/fa6";
import { useRootStore } from "stores/useRootStore";

import { ScoreSection } from "./ScoreSection";

export function HeroSection({
  onCTAClick,
  sectionID,
}: {
  onCTAClick: (sectionID: string) => void;
  sectionID: string;
}) {
  const store = useRootStore();
  const astrologer = getAstrologerOrDefault(store.user.astrologer_persona_id);
  const user = store.user;

  const getHeadline = () => {
    if (user.initial_sales_funnel_theme === "relationships") {
      return (
        <>
          Go From Knowing Little to Everything About{" "}
          <Text as="span" color="purple.300" textDecoration="underline">
            Your Love, Relationships and Compatibility
          </Text>{" "}
          - By{" "}
          <Text as="span" textDecoration={"underline"}>
            Unlocking
          </Text>{" "}
          Your Personal Astrologer Now
        </>
      );
    }
    return (
      <>
        Go From Knowing Little to Everything About{" "}
        <Text as="span" color="purple.300" textDecoration="underline">
          Your Life, Relationships and Future
        </Text>{" "}
        - By{" "}
        <Text as="span" textDecoration={"underline"}>
          Unlocking
        </Text>{" "}
        Your Personal Astrologer Now
      </>
    );
  };

  const getQuestionExamples = () => {
    if (user.initial_sales_funnel_theme === "relationships") {
      return (
        <>
          <Text fontStyle={"italic"}>
            💕 "Who's my <Span textDecoration={"underline"}>ideal partner</Span>
            ..."
          </Text>

          <Text fontStyle={"italic"}>
            💏 "Will my ex come back to my life ..."
          </Text>

          <Text fontStyle={"italic"}>💔 "Is my partner cheating on me..."</Text>

          <Text fontStyle={"italic"}>☠️ "Am I in a toxic relationship..."</Text>

          <Text fontStyle={"italic"}>
            🛌 "Who's my ideal sexual partner..."
          </Text>
        </>
      );
    }
    return (
      <>
        <Text fontStyle={"italic"}>
          💕 "Who's my <Span textDecoration={"underline"}>ideal partner</Span>
          ..."
        </Text>

        <Text fontStyle={"italic"}>
          😞 "Why do I feel <Span textDecoration={"underline"}>lonely</Span>
          ..."
        </Text>

        <Text fontStyle={"italic"}>
          🔮 "What awaits me in the{" "}
          <Span textDecoration={"underline"}>future</Span>..."
        </Text>
      </>
    );
  };

  return (
    <Stack>
      <Text
        textAlign={"center"}
        fontSize={"2xl"}
        fontWeight={"semibold"}
        lineHeight={1.3}
        color={"white"}
        mb={2}
        px={2}
      >
        {getHeadline()}
      </Text>

      <Stack
        gridTemplateColumns={"60px 1fr"}
        alignItems={"center"}
        spacing={6}
        mx="auto"
        bgGradient={"linear(to-b, purple.900, transparent)"}
        pt={6}
        px={5}
        mt={6}
        width={"full"}
        borderTopRadius={"34px"}
        position={"relative"}
      >
        <Stack
          position={"absolute"}
          top={0}
          left={2}
          transform={"translateY(-50%)"}
        >
          <Image
            zIndex={1}
            src={astrologer.avatarRegular}
            borderRadius={"50%"}
            width={"60px"}
            border={"4px solid purple"}
            borderColor={"purple.900"}
          />
          <Text
            fontSize={"2xl"}
            position={"absolute"}
            zIndex={2}
            bottom={0}
            right={0}
            lineHeight={1}
            transform={"translateX(50%) translateY(-70%) rotate(-20deg)"}
          >
            🔒
          </Text>
        </Stack>

        <Stack
          color={"white"}
          fontWeight={"bold"}
          fontSize={"md"}
          flex={1}
          spacing={1}
          mx="auto"
          textAlign={"center"}
        >
          {getQuestionExamples()}
          <Text>Find out it all!</Text>
        </Stack>

        <Text fontSize={"xs"} textAlign={"center"}>
          <Icon as={FaRegCircleCheck} color="purple.200" />{" "}
          <Span fontWeight={"bold"}>100%</Span> personalized for{" "}
          <Span fontWeight={"bold"}>
            {store.user.name ? store.user.name : "You"}
          </Span>
          ,{" "}
          <Span color="whiteAlpha.700">
            {Intl.DateTimeFormat("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            }).format(new Date(store.user.dob_utc))}
            ,{" "}
          </Span>
          {store.user.birth_place_formatted_text && (
            <Span color="whiteAlpha.700">
              {store.user.birth_place_formatted_text}
            </Span>
          )}
        </Text>
      </Stack>

      <CTAButton
        mt={3}
        onClick={() => onCTAClick(sectionID)}
        textTransform={"uppercase"}
        py={7}
      >
        <Stack spacing={1} alignItems={"center"}>
          <Text fontWeight={"bold"}>🔒 Unlock My Astrologer Now</Text>
          <Text fontSize={"sm"} opacity={0.8}>
            (+ Claim FREE gifts!)
            <Icon ml={2} as={FaArrowRight} fontSize={"sm"} />
          </Text>
        </Stack>
      </CTAButton>

      <ScoreSection />
    </Stack>
  );
}
