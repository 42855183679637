import React from "react";
import { InstantReading } from "services/coreapi";

import { useRootStore } from "./useRootStore";

export type PermissionProviderType = {
  hasSubscription: () => boolean;
  hasAccessToReading: (instantReading: InstantReading) => boolean;
};

export const PermissionProviderCtx =
  React.createContext<PermissionProviderType | null>(null);

export function PermissionProvider({ children }: React.PropsWithChildren) {
  const store = useRootStore();

  const value = React.useMemo<PermissionProviderType>(() => {
    return {
      hasSubscription: () =>
        store.user.subscription_status === "active" ||
        store.user.subscription_status === "trialing",
      hasAccessToReading: (instantReading: InstantReading) => {
        return (
          store.user.subscription_status === "active" ||
          store.user.subscription_status === "trialing" ||
          Boolean(instantReading.is_locked) === false
        );
      },
    };
  }, [store.user]);

  return (
    <PermissionProviderCtx.Provider value={value}>
      {children}
    </PermissionProviderCtx.Provider>
  );
}
