import { Stack, Text, TextProps } from "@chakra-ui/react";
import { APP_CONFIG } from "data/subscriptions";
import { SecondaryTopNav } from "src/layouts/SecondaryTopNav";

function SectionTitle(props: TextProps) {
  return (
    <Text
      textAlign={"left"}
      fontSize={"md"}
      fontWeight={"bold"}
      color="text.main"
      {...props}
    />
  );
}

export function PrivacyPolicyPage() {
  return (
    <Stack spacing={8}>
      <SecondaryTopNav />

      <Stack spacing={3}>
        <SectionTitle>1.Introduction</SectionTitle>
        <Text>
          Welcome to {APP_CONFIG.name}. We are committed to protecting your
          privacy and ensuring the security of your personal information. This
          Privacy Policy outlines how we collect, use, disclose, and safeguard
          your data when you visit our website.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>2. Information We Collect</SectionTitle>
        <Text>
          We may collect various types of information, including: Personal
          Information: This includes names, email addresses, and any other
          information you provide voluntarily when using our services or
          contacting us. Usage Data: We collect data on how you interact with
          our website, such as pages visited, time spent, and other analytics.
          Cookies and Tracking: We may use cookies and similar tracking
          technologies to enhance your browsing experience. You can manage your
          cookie preferences through your browser settings.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>3. Use of Information</SectionTitle>
        <Text>
          We use the collected information for the following purposes:
        </Text>
        <Text>- To provide and maintain our website and services.</Text>
        <Text>
          - To communicate with you, including responding to inquiries and
          providing updates.
        </Text>
        <Text>
          - To analyze and improve our website's functionality and user
          experience.
        </Text>
        <Text>- To comply with legal obligations.</Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>4. Data Sharing</SectionTitle>
        <Text>
          We do not share your personal information with third parties, except
          in cases where it is necessary to provide specific services or when
          required by law.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>5. Security</SectionTitle>
        <Text>
          We take appropriate measures to protect your data from unauthorized
          access, disclosure, alteration, or destruction.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>6. Your Choices</SectionTitle>
        <Text>You have the right to:</Text>
        <Text>- Access your personal information.</Text>
        <Text>- Correct any inaccuracies in your data.</Text>
        <Text>- Request the deletion of your data.</Text>
        <Text>- Object to the processing of your data.</Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>7. Children's Privacy</SectionTitle>
        <Text>
          Our website is not directed at children under the age of 13. We do not
          knowingly collect personal information from children.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>8. Changes to this Privacy Policy</SectionTitle>
        <Text>
          We may update this Privacy Policy to reflect changes in our data
          practices. We encourage you to review this page periodically for
          updates. The date of the last revision will be indicated at the bottom
          of the page.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>9. Minors</SectionTitle>
        <Text>
          The Site is not intended for individuals under the age of 18.
        </Text>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>10. Contact Us</SectionTitle>
        <Text>
          If you have any questions or concerns about our Privacy Policy or how
          we handle your data, please contact us at:
        </Text>
        <a style={{ color: "#005eed" }} href={`mailto:${APP_CONFIG.email}`}>
          {APP_CONFIG.email}
        </a>
        <Text>This Privacy Policy was last updated on 2024.01.05</Text>
      </Stack>
    </Stack>
  );
}
