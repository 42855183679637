export function loadGoogleTagManager(id: string) {
  // Create a new script element
  var script = document.createElement("script");

  // Set the script attributes
  script.async = true;
  script.src = "https://www.googletagmanager.com/gtag/js?id=" + id;

  // Append the script to the document head
  document.head.appendChild(script);

  // Initialize dataLayer if it doesn't exist
  (window as any).dataLayer = (window as any).dataLayer || [];

  // Define the gtag function
  function gtag() {
    (window as any).dataLayer.push(arguments);
  }

  // Set the gtag function to window object
  (window as any).gtag = gtag;

  // @ts-expect-error
  gtag("js", new Date());
  // @ts-expect-error
  gtag("config", id);
}
