import { wait } from "lib/wait";
import React from "react";
import { InstantReading } from "services/coreapi";

import { ASPECTS, TRANSITS } from "./data";
import { LoadingState } from "./types";
import { useReading } from "./useReading";

type Result =
  | {
      status: "initial";
    }
  | {
      status: "loading";
      state: LoadingState;
    }
  | {
      status: "ok";
      data: InstantReading;
    }
  | {
      status: "error";
    }
  | {
      status: "error-inappropriate";
      data: { answer: string };
    };

/**
 * Combines data fetching with fake loading
 */
export function useAstrologer(): {
  ask: (question: string) => Promise<void>;
  result: Result;
} {
  const reading = useReading();

  const [firstPhaseDone, setFirstPhaseDone] = React.useState<boolean>(false);
  const [secondPhaseDone, setSecondPhaseDone] = React.useState<boolean>(false);

  const [result, setResult] = React.useState<Result>({
    status: "initial",
  });

  React.useEffect(() => {
    async function handleError() {
      setResult({
        status: "loading",
        state: {
          id: "interpretation",
          title: "Preparing answer...",
          progressItems: [],
          progressAnimationDuration: 3000,
        },
      });

      await wait(3000);

      if (reading.result.status === "error") {
        setResult({
          status: "error",
        });
      }

      if (reading.result.status === "error-inappropriate") {
        setResult({
          status: "error-inappropriate",
          data: reading.result.data,
        });
      }
    }

    if (firstPhaseDone) {
      if (reading.result.status === "ok") {
        startSecondFakeLoading(reading.result.data.determinant_aspects);

        return;
      }

      if (
        reading.result.status === "error" ||
        reading.result.status === "error-inappropriate"
      ) {
        handleError();

        return;
      }
    }
  }, [reading.result, firstPhaseDone]);

  React.useEffect(() => {
    if (secondPhaseDone) {
      if (reading.result.status === "ok") {
        setResult({
          status: "ok",
          data: reading.result.data,
        });

        return;
      }

      if (reading.result.status === "error") {
        setResult({
          status: "error",
        });

        return;
      }

      if (reading.result.status === "error-inappropriate") {
        setResult({
          status: "error-inappropriate",
          data: {
            answer: reading.result.data.answer,
          },
        });

        return;
      }
    }
  }, [reading.result, secondPhaseDone]);

  async function startFirstFakeLoading() {
    setResult({
      status: "loading",
      state: {
        id: "natal-chart",
        title: "Analysing natal chart...",
        progressItems: [],
        progressAnimationDuration: 2000,
      },
    });

    await wait(2000);

    setResult({
      status: "loading",
      state: {
        id: "transit",
        title: "Calculating transits...",
        progressItems: TRANSITS,
        progressAnimationDuration: 2000,
      },
    });

    await wait(2000);

    setResult({
      status: "loading",
      state: {
        id: "aspects",
        title: "Finding aspects...",
        progressItems: ASPECTS,
        progressAnimationDuration: 3000,
      },
    });

    await wait(3000);

    // added to ensure that aspect step (above) doesnt look "frozen" if data fecthing takes too long
    setResult({
      status: "loading",
      state: {
        id: "aspects",
        title: "Identifying dominant influences...",
        progressItems: [],
        progressAnimationDuration: 0,
      },
    });

    setFirstPhaseDone(true);
  }

  async function startSecondFakeLoading(aspects: string[]) {
    setResult({
      status: "loading",
      state: {
        id: "important-aspects",
        title: "Determining important aspects...",
        progressItems: aspects,
        progressAnimationDuration: 2000,
      },
    });

    await wait(2000);

    setResult({
      status: "loading",
      state: {
        id: "interpretation",
        title: "Preparing answer...",
        progressItems: [],
        progressAnimationDuration: 3000,
      },
    });

    await wait(3000);

    setSecondPhaseDone(true);
  }

  return {
    ask: async (question: string) => {
      if (result.status === "loading") {
        return;
      }

      reading.create(question);

      startFirstFakeLoading();
    },
    result: result,
  };
}
