import React from "react";

import { AuthStoreCtx, AuthStoreType } from "./AuthStore";

export function useAuthStore(): AuthStoreType {
  const ctx = React.useContext(AuthStoreCtx);
  if (!ctx) {
    throw new Error("useAuth has to be used within AuthStore");
  }

  return ctx;
}
