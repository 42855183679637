import { Button, keyframes } from "@chakra-ui/react";
import { ComponentProps } from "react";

export function CTAButton(props: ComponentProps<typeof Button>) {
  const PULSE_ANIMATION = keyframes`
  0% { box-shadow: 0 0 0 0px #aa98d3; }
  100% { box-shadow: 0 0 0 15px rgba(0, 0, 0, 0); }; }
`;

  return (
    <Button
      py={3}
      size={"lg"}
      fontSize={"md"}
      variant={"solid"}
      colorScheme="purple"
      width={"full"}
      borderRadius={"full"}
      border={"4px solid #aa98d3"}
      animation={`${PULSE_ANIMATION} 1.5s ease-in-out  infinite`}
      {...props}
    />
  );
}
