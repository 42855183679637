import { ScrollToHashElement, ScrollToTop } from "lib/components";
import { CheckoutModal } from "pages/CheckoutModal";
import { SpecialOfferModal } from "pages/SpecialOfferModal";

export function AuthLayout({ children }: React.PropsWithChildren) {
  return (
    <>
      <ScrollToTop />
      <ScrollToHashElement />
      <CheckoutModal />
      <SpecialOfferModal />

      {children}
    </>
  );
}
