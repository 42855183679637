import { CoreServerType } from "@astropal/api-client";
import { edenFetch } from "@elysiajs/eden";
import OpenAI from "openai";

import {
  DailyReading,
  InstantReading,
  InstantReadingWithBalance,
  NatalChart,
  NatalChartReading,
  ServerResponseError,
  SuggestedQuestion,
  User,
} from "./types";
import { resolveUser } from "./utils";

const apiHost =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "https://api.intuvist.com";

const eden = edenFetch<CoreServerType>(apiHost);

export class CoreAPI {
  private client: typeof eden;

  constructor(input: { onError: (err: ServerResponseError) => void }) {
    this.client = async (...args) => {
      const res = await eden(...args);

      if (res.error) {
        // @ts-expect-error type issues
        input.onError(res.error);
      }

      return res;
    };
  }

  getUserByEmail = async (email: string): Promise<User> => {
    const res = await this.client("/getUserByEmail", { query: { email } });
    return resolveUser(res.data!);
  };

  getUserByID = async (userID: string): Promise<User> => {
    const res = await this.client("/getUserByID", { query: { id: userID } });
    return resolveUser(res.data!);
  };

  getDailyReading = async (userID: string): Promise<DailyReading> => {
    const res = await this.client("/getDailyReading", {
      query: { userID },
    });
    return res.data!;
  };

  getNatalChartReading = async (userID: string): Promise<NatalChartReading> => {
    const res = await this.client("/getNatalChartReading", {
      query: { userID },
    });

    return res.data!;
  };

  getNatalChart = async (userID: string): Promise<NatalChart> => {
    const res = await this.client("/getNatalChartByUserID", {
      query: { userID },
    });
    return res.data!;
  };

  getSuggestedQuestions = async (
    userID: string,
  ): Promise<SuggestedQuestion[]> => {
    const res = await this.client("/suggestedQuestions", {
      query: { userID },
    });

    return res.data!.suggestedQuestions;
  };

  createInstantReading = async (
    userID: string,
    question: string,
  ): Promise<InstantReadingWithBalance> => {
    const res = await this.client("/answerQuestion", {
      body: { questionText: question, userID },
      method: "POST",
    });

    return {
      answer: res.data!.answer,
      creditBalance: res.data!.creditBalance,
    };
  };

  getAllInstantReadings = async (userID: string): Promise<InstantReading[]> => {
    const res = await this.client("/getAllQuestionAnswers", {
      query: { userID },
    });
    return res.data!;
  };

  getInstantReading = async (
    userID: string,
    readingID: string,
  ): Promise<InstantReading> => {
    const res = await this.client("/getQuestionAnswer", {
      query: { userID, questionID: readingID },
    });
    return res.data!;
  };

  unsubscribeAllEmails = async (userID: string) => {
    return this.client("/unsubscribeAllEmails", {
      body: { userID },
      method: "POST",
    });
  };

  createSubscription = async ({
    userID,
    priceID,
    oneTimeFeePriceID,
    couponID,
  }: {
    userID: string;
    priceID: string;
    oneTimeFeePriceID?: string;
    couponID?: string;
  }) => {
    const res = await this.client("/payments/createSubscription", {
      method: "POST",
      body: {
        userID,
        priceID,
        oneTimeFeePriceID,
        couponID,
      },
    });
    return res.data!;
  };

  createLifetimePayment = async ({
    userID,
    priceID,
  }: {
    userID: string;
    priceID: string;
    oneTimeFeePriceID?: string;
    couponID?: string;
  }) => {
    const res = await this.client(
      "/payments/createPaymentIntent_lifetimeLicense",
      {
        method: "POST",
        body: {
          userID,
          priceID,
        },
      },
    );
    return res.data!;
  };

  getCurrentPricingPlans = async () => {
    const res = await this.client("/currentPricingPlans", {
      method: "GET",
    });
    return res.data!;
  };

  getTrialPricingPlan = async () => {
    const res = await this.client("/currentPaidTrialPricingPlan", {
      method: "GET",
    });
    return res.data!;
  };

  getInstantReadingArrayBuffer = async (
    text: string,
    avatarGender: "male" | "female",
  ): Promise<ArrayBuffer> => {
    if (process.env.NODE_ENV === "development") {
      return new ArrayBuffer(5);
    }

    const openai = new OpenAI({
      apiKey: import.meta.env.VITE_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true,
    });

    const mp3 = await openai.audio.speech.create({
      model: "tts-1",
      voice: avatarGender === "female" ? "shimmer" : "onyx",
      input: text,
    });

    const arrayBuffer = await mp3.arrayBuffer();

    return arrayBuffer;
  };
}
