import { AstrologerID } from "lib/astrologers";
import { getZodiacSignSVG } from "lib/zodiacSigns";

import { User, UserServer, ZodiacSign } from "./types";

export function resolveUser(user: UserServer): User {
  const zodiacSign = user.zodiac_sign as ZodiacSign;

  return {
    ...user,
    astrologer_persona_id: user.astrologer_persona_id as AstrologerID,
    zodiac_sign_svg: getZodiacSignSVG(zodiacSign),
    CreditBalance: {
      userID: user.id,
      balance: user.CreditBalance?.balance ?? 0,
    },
  };
}
