import { PricingPlan } from "services/coreapi";

export const APP_CONFIG = {
  name: "Intuvist",
  email: "info@intuvist.com",
  url: "https://app.intuvist.com",
  urlTitle: "app.intuvist.com",
};

export type ResolvedPricingPlan = PricingPlan & {
  billingText: string;
  dailyPriceNow: number;
  dailyPriceBefore: number;
  unitAmountNow: number;
  unitAmountBefore: number;
  discountPercentage: number;
  discountAbs: number;
};

export function resolvePricingPlan(
  plan: PricingPlan,
  discountPercentage: number,
): ResolvedPricingPlan {
  const getDailyUnit = () => {
    if (!plan.recurring) {
      return 0;
    }
    if (plan.recurring.interval === "month") {
      return 30 * plan.recurring.interval_count;
    }
    if (plan.recurring.interval === "week") {
      return 7.5 * plan.recurring.interval_count;
    }
    return 1;
  };

  const discountFr = discountPercentage / 100;

  const unitAmountNow = plan.unit_amount / 100;
  const unitAmountBefore = Math.floor(unitAmountNow / (1 - discountFr));
  const discountAbs = unitAmountBefore - unitAmountNow;

  const dailyPriceNow = plan.unit_amount / 100 / getDailyUnit();
  const dailyPriceBefore = dailyPriceNow / (1 - discountFr);

  const billedEveryText = () => {
    if (!plan.recurring) {
      return "Billed once, use forever";
    }
    const u = plan.recurring.interval_count;
    const t = u === 1 ? plan.recurring.interval : plan.recurring.interval + "s";
    return `Billed every ${u} ${t}`;
  };

  return {
    ...plan,
    dailyPriceNow,
    dailyPriceBefore,
    billingText: billedEveryText(),
    unitAmountBefore,
    unitAmountNow,
    discountPercentage,
    discountAbs,
  };
}
