import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import { useRootStore } from "stores/useRootStore";

import { CheckoutView } from "./CheckoutView";

const CHECKOUT_QUERY_PARAM = "checkout";
const PLANID_QUERY_PARAM = "planID";

export function CheckoutModal() {
  const navigate = useNavigate();
  const location = useLocation();
  const store = useRootStore();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [planID, setPlanID] = React.useState<string | null>(null);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const checkoutParam = params.get(CHECKOUT_QUERY_PARAM);
    const planIDParam = params.get(PLANID_QUERY_PARAM);

    if (checkoutParam === "true" && planIDParam) {
      setIsOpen(true);
      setPlanID(planIDParam);
    } else {
      setIsOpen(false);
      setPlanID(null);
    }
  }, [location]);

  function close() {
    if (planID) {
      store.setCurrentPlanID(planID);
    }

    const params = new URLSearchParams(location.search);
    params.delete(CHECKOUT_QUERY_PARAM);
    params.delete(PLANID_QUERY_PARAM);
    params.delete("applyCoupon");

    navigate(`${location.pathname}?${params}`);
  }

  return (
    <Modal isOpen={Boolean(isOpen && planID)} onClose={close} isCentered>
      <ModalOverlay backgroundColor={"blackAlpha.800"} />

      <ModalContent width={"95%"} mt={"20px"} borderRadius={"xl"}>
        <ModalHeader py={3} color="black">
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody px={4}>
          <Stack mb={10}>
            <CheckoutView planID={planID!} />
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
