import {
  Container,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import gift from "assets/gift-2-2.png";
import { CTAButton } from "lib/components";
import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router";
import { useServices } from "services/useServices";
import { useRootStore } from "stores/useRootStore";

export function SpecialOfferModal() {
  const { tracking } = useServices();
  const navigate = useNavigate();
  const location = useLocation();
  const store = useRootStore();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [wasOpen, setWasOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!wasOpen && store.currentPlanID !== null) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [store.currentPlanID, wasOpen]);

  function close() {
    setIsOpen(false);
    setWasOpen(true);
  }

  if (!store.currentPlanID) {
    return;
  }

  return (
    <Modal isOpen={isOpen} onClose={close} size={"sm"}>
      <ModalOverlay backgroundColor={"blackAlpha.800"} />

      <ModalContent
        width={"95%"}
        mt={"20px"}
        borderRadius={"xl"}
        bgGradient={"linear(to-br, red.300, purple.800)"}
        color="white"
        py={3}
      >
        <ModalHeader py={3}>
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <Content
            planID={store.currentPlanID}
            onSubmit={() => {
              tracking.capture("checkout_btn_click", {
                sectionID: "discount-modal",
                planID: "paid_trial_one_time_fee",
              });

              setIsOpen(false);

              navigate(
                `${location.pathname}?checkout=true&planID=${store.currentPlanID}&applyCoupon=true`,
              );
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function Content({
  onSubmit,
  planID,
}: {
  onSubmit: () => void;
  planID: string;
}) {
  return (
    <Container px={0}>
      <Stack spacing={4}>
        <Text
          textAlign={"center"}
          fontSize={"2xl"}
          fontWeight={"semibold"}
          lineHeight={1.3}
          color={"white"}
          px={2}
        >
          Get a{" "}
          <Text as="span" color="yellow.100" textDecor="underline">
            FREE 6-Month Astrological Forecast{" "}
          </Text>{" "}
          When You Choose Your{" "}
          {planID === "1-month-plan" ? "Monthly Plan" : "Lifetime Access"}
        </Text>

        <Stack position="relative" mx="auto" width={"130px"}>
          <Image src={gift} width={"full"} mx={"auto"} />
          <Text
            fontSize={"xl"}
            fontWeight={"bold"}
            backgroundColor={"red.500"}
            px={3}
            py={1}
            position={"absolute"}
            bottom={"20px"}
            right={"-20px"}
            transform={"rotate(-20deg)"}
          >
            <Text as="span" textDecor={"line-through"}>
              $79
            </Text>{" "}
            FREE
          </Text>
        </Stack>

        <Stack mx="auto" color="white" spacing={0} fontWeight={"bold"}>
          <Stack direction={"row"} alignItems={"center"} spacing={3}>
            <Text fontSize={"3xl"}>🔥</Text>
            <Text fontSize={"md"}>
              In-depth 6-months forecast of opportunities and dangers
            </Text>
          </Stack>

          <Stack direction={"row"} alignItems={"center"} spacing={3}>
            <Text fontSize={"3xl"}>🎁</Text>

            <Text fontSize={"md"}>A gift worth $79 for FREE</Text>
          </Stack>
        </Stack>

        <CTAButton onClick={onSubmit} textTransform={"uppercase"} py={7}>
          <Stack spacing={1} alignItems={"center"}>
            <Text fontWeight={"bold"}>
              Choose{" "}
              {planID === "1-month-plan" ? "Monthly Plan" : "Lifetime Access"}{" "}
              Now{" "}
            </Text>
            <Text fontSize={"sm"} opacity={0.8}>
              (I want My FREE gift!)
              <Icon ml={2} as={FaArrowRight} fontSize={"sm"} />
            </Text>
          </Stack>
        </CTAButton>
      </Stack>
    </Container>
  );
}
