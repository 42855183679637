import { ChakraProvider } from "@chakra-ui/react";
import { AstrologerPage } from "pages/AstrologerPage";
import CheckoutErrorPage from "pages/CheckoutErrorPage";
import { ContactsPage } from "pages/ContactsPage";
import { FaceReadingPage } from "pages/FaceReadingPage";
import { InstantReadingPage } from "pages/InstantReadingPage";
import { InstantReadingsPage } from "pages/InstantReadingsPage";
import { LoginPage } from "pages/LoginPage";
import { NewInstantReadingPage } from "pages/NewInstantReadingPage";
import { OnboardingPage } from "pages/OnboardingPage";
import { PageNotFound } from "pages/PageNotFound";
import { PrivacyPolicyPage } from "pages/PrivacyPolicyPage";
import { ProfilePage } from "pages/ProfilePage";
import { SuccessCheckoutPage } from "pages/SuccessCheckoutPage";
import { SummaryPage } from "pages/SummaryPage";
import { TermsAndConditionsPage } from "pages/TermsAndConditionsPage";
import { UnsubscribeAllEmails } from "pages/UnsubscribeAllEmails";
import { WelcomePage } from "pages/WelcomePage";
import {
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { ReadingRepo } from "repositories/ReadingRepo";
import { Services } from "services/Services";
import { AuthLayout } from "src/layouts/AuthLayout";
import { theme } from "src/theme";
import { AuthStore } from "stores/AuthStore";
import { NotificationStore } from "stores/NotificationStore";
import { PermissionProvider } from "stores/PermissionProvider";
import { RootStore } from "stores/RootStore";

import { MainLayout } from "../layouts/MainLayout";
import { RouteGuard } from "./RouteGuard";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path={"/"}
      errorElement={<PageNotFound />}
      element={
        <ChakraProvider theme={theme}>
          <NotificationStore>
            <Services>
              <AuthStore>
                <RouteGuard>
                  <Outlet />
                </RouteGuard>
              </AuthStore>
            </Services>
          </NotificationStore>
        </ChakraProvider>
      }
    >
      <Route
        path={"/unsubscribe-all-emails"}
        element={<UnsubscribeAllEmails />}
      />

      <Route path={"/login"} element={<LoginPage />} />

      <Route
        path={"/"}
        element={
          <RootStore>
            <PermissionProvider>
              <ReadingRepo>
                <AuthLayout>
                  <Outlet />
                </AuthLayout>
              </ReadingRepo>
            </PermissionProvider>
          </RootStore>
        }
      >
        <Route path={"/intro"} element={<WelcomePage />} />

        <Route
          path="/success-checkout"
          element={
            <MainLayout>
              <SuccessCheckoutPage />
            </MainLayout>
          }
        />

        <Route
          path={"/onboarding"}
          element={
            <MainLayout>
              <OnboardingPage />
            </MainLayout>
          }
        />

        <Route
          path={"/astrologer"}
          element={
            <MainLayout>
              <AstrologerPage />
            </MainLayout>
          }
        />

        <Route
          path={"/astrologer/new-instant-reading"}
          element={
            <MainLayout>
              <NewInstantReadingPage />
            </MainLayout>
          }
        />

        <Route
          path={"/astrologer/instant-readings"}
          element={
            <MainLayout>
              <InstantReadingsPage />
            </MainLayout>
          }
        />

        <Route
          path={"/astrologer/instant-readings/:instantReadingID"}
          element={
            <MainLayout>
              <InstantReadingPage />
            </MainLayout>
          }
        />

        <Route path={"/summary"} element={<SummaryPage />} />

        <Route
          path={"/checkout-error"}
          element={
            <MainLayout>
              <CheckoutErrorPage />
            </MainLayout>
          }
        />

        <Route
          path={"/profile"}
          element={
            <MainLayout>
              <ProfilePage />
            </MainLayout>
          }
        />

        <Route
          path={"/face-reading"}
          element={
            <MainLayout>
              <FaceReadingPage />
            </MainLayout>
          }
        />

        <Route
          path={"/contact-us"}
          element={
            <MainLayout>
              <ContactsPage />
            </MainLayout>
          }
        />

        <Route
          path={"/privacy-policy"}
          element={
            <MainLayout>
              <PrivacyPolicyPage />
            </MainLayout>
          }
        />

        <Route
          path={"/terms-and-conditions"}
          element={
            <MainLayout>
              <TermsAndConditionsPage />
            </MainLayout>
          }
        />
      </Route>
    </Route>,
  ),
);
