import React from "react";

import { RootStoreCtx, RootStoreType } from "./RootStore";

export function useRootStore(): RootStoreType {
  const ctx = React.useContext(RootStoreCtx);
  if (!ctx) {
    throw new Error("useRootStore has to be used within RootStore");
  }

  return ctx;
}
