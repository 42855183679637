import avatar1Thinking from "assets/avatar-1-thinking.jpg";
import avatar1 from "assets/avatar-1.jpg";
import avatar2Thinking from "assets/avatar-2-thinking.jpg";
import avatar2 from "assets/avatar-2.jpg";
import avatar3Thinking from "assets/avatar-3-thinking.jpg";
import avatar3 from "assets/avatar-3.jpg";
import avatar4Thinking from "assets/avatar-4-thinking.jpg";
import avatar4 from "assets/avatar-4.jpg";
import avatar5Thinking from "assets/avatar-5-thinking.jpg";
import avatar5 from "assets/avatar-5.jpg";

export type Astrologer = {
  id: AstrologerID;
  name: string;
  avatarRegular: string;
  avatarThinking: string;
  gender: "male" | "female";
};

export type AstrologerID = "celestia" | "luna" | "sophia" | "orion" | "vega";

const astrologers: Astrologer[] = [
  {
    id: "celestia",
    name: "Celestia",
    avatarRegular: avatar1,
    avatarThinking: avatar1Thinking,
    gender: "female",
  },
  {
    id: "luna",
    name: "Luna",
    avatarRegular: avatar2,
    avatarThinking: avatar2Thinking,
    gender: "female",
  },
  {
    id: "sophia",
    name: "Sophia",
    avatarRegular: avatar3,
    avatarThinking: avatar3Thinking,
    gender: "female",
  },
  {
    id: "orion",
    name: "Orion",
    avatarRegular: avatar4,
    avatarThinking: avatar4Thinking,
    gender: "male",
  },
  {
    id: "vega",
    name: "Vega",
    avatarRegular: avatar5,
    avatarThinking: avatar5Thinking,
    gender: "female",
  },
];

export function getAstrologerOrDefault(id?: string | null) {
  return astrologers.find((it) => it.id === id) ?? astrologers[0];
}
