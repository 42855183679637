import { Stack, Text } from "@chakra-ui/react";
import { SecondaryTopNav } from "src/layouts/SecondaryTopNav";

export default function CheckoutErrorPage() {
  return (
    <Stack spacing={6}>
      <SecondaryTopNav />

      <Text
        textAlign={"left"}
        fontSize={"xl"}
        fontWeight={"semibold"}
        color="text.main"
      >
        Unable to complete your order
      </Text>

      <Stack
        backgroundColor={"orange.100"}
        p={4}
        color="orange.800"
        fontWeight={"semibold"}
        borderRadius={8}
        spacing={5}
      >
        <Text>Oops! We were unable to complete your order.</Text>

        <Text>
          Rest assured, no charges have been made. Our team is working
          diligently to resolve the issue.
        </Text>

        <Text>
          We kindly ask that you check back in a few hours. Thank you for your
          understanding!
        </Text>
      </Stack>
    </Stack>
  );
}
