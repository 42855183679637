import { StarIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";

export function ScoreSection() {
  return (
    <Flex gap={4} justifyContent={"center"} alignItems={"center"}>
      <Flex
        alignItems={"center"}
        borderRadius={"full"}
        px={4}
        py={2}
        direction={"column"}
      >
        <Flex alignItems={"center"} gap={2}>
          <Flex gap={1}>
            {Array(5)
              .fill("")
              .map((_, idx) => (
                <StarIcon key={idx} color="orange.300" boxSize={"14px"} />
              ))}
          </Flex>{" "}
          <Text fontWeight={"bold"} fontSize={"lg"}>
            4.8
          </Text>
          <Text fontWeight={"bold"} fontSize={"sm"}>
            The Intuvist
          </Text>
        </Flex>
        <Text fontSize={"xs"} textAlign={"center"}>
          "Enlightening experience!" (42000+ reviews)
        </Text>
      </Flex>
    </Flex>
  );
}
