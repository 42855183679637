import { Icon, Text } from "@chakra-ui/react";
import { IoSparklesSharp } from "react-icons/io5";

export function AstrologerErrorFallback() {
  return (
    <Text textAlign={"center"} color={"fallbackText"} maxW={200} mx={"auto"}>
      Unfortunatelly we coudn't reach the stars. Please try again!{" "}
      <Icon as={IoSparklesSharp} boxSize={4} color={"fallbackText"} />
    </Text>
  );
}
