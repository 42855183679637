import { Image, Stack, Text } from "@chakra-ui/react";

export function FreeGiftItem(props: {
  img: string;
  title: string;
  subtitle: string;
  price: number;
}) {
  return (
    <Stack spacing={2}>
      <Stack overflow={"hidden"} position={"relative"}>
        <Image
          alt=""
          src={props.img}
          style={{
            flexShrink: 0,
          }}
        />
      </Stack>

      <Stack spacing={1} fontSize={"sm"}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={2}
          fontSize={"lg"}
        >
          <Text textDecoration={"line-through"} color="white">
            ${props.price}
          </Text>
          <Text fontWeight={"bold"} color="#34ec8a">
            FREE 🎁
          </Text>
        </Stack>

        <Text fontSize={"lg"} fontWeight={"semibold"} lineHeight={1.3}>
          {props.title}
        </Text>
        <Text fontSize={"xs"} color="whiteAlpha.800">
          {props.subtitle}
        </Text>
      </Stack>
    </Stack>
  );
}
