import { SkeletonProps, Skeleton as SkeletonRaw } from "@chakra-ui/react";

export function Skeleton(props: SkeletonProps) {
  return (
    <SkeletonRaw
      startColor="skeletonStart"
      endColor="skeletonEnd"
      borderRadius={"lg"}
      {...props}
    />
  );
}
