import { Stack, StackProps, Text, TextProps } from "@chakra-ui/react";
import { capitalizeFirstLetter, formatToTimeString } from "lib/strings";
import { SecondaryTopNav } from "src/layouts/SecondaryTopNav";
import { usePermissions } from "stores/usePermissionProvider";
import { useRootStore } from "stores/useRootStore";

function SectionTitle(props: TextProps) {
  return <Text fontSize={"lg"} fontWeight={"semibold"} {...props} />;
}

function SectionText(props: TextProps) {
  return <Text color="whiteAlpha.700" {...props} />;
}

function Section(props: StackProps) {
  return (
    <Stack
      p={4}
      borderRadius={"xl"}
      backgroundColor={"whiteAlpha.200"}
      {...props}
    />
  );
}

function Item(props: StackProps) {
  return (
    <Stack
      px={2}
      py={1}
      borderRadius={"lg"}
      backgroundColor={"blackAlpha.400"}
      {...props}
    />
  );
}

export function ProfilePage() {
  const store = useRootStore();
  const permissions = usePermissions();

  return (
    <Stack>
      <SecondaryTopNav />

      <Stack spacing={3}>
        <Text fontSize={"2xl"} fontWeight={"semibold"}>
          Your Profile
        </Text>

        <Section>
          <SectionTitle>Account</SectionTitle>

          <SectionText>
            Email: <SectionText as="span">{store.user.email}</SectionText>
          </SectionText>
        </Section>

        <Section>
          <SectionTitle>Profile</SectionTitle>

          {/* <Item>
            <SectionText>
              Name:{" "}
              <SectionText as="span" color="white">
                {capitalizeFirstLetter(store.user.name) ?? "-"}
              </SectionText>
            </SectionText>
          </Item> */}

          <Item>
            <SectionText>
              Gender:{" "}
              <SectionText as="span" color="white">
                {capitalizeFirstLetter(store.user.gender)}
              </SectionText>
            </SectionText>
          </Item>

          <Item>
            <SectionText>
              Date of birth:{" "}
              <SectionText as="span" color="white">
                {Intl.DateTimeFormat("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                }).format(new Date(store.user.dob_utc))}
              </SectionText>
            </SectionText>
          </Item>

          <Item>
            <SectionText>
              Birth time:{" "}
              <SectionText as="span" color="white">
                {formatToTimeString(
                  store.user.dob_local_hour,
                  store.user.dob_local_minute,
                )}
              </SectionText>
            </SectionText>
          </Item>

          <Item>
            <SectionText>
              Birth location:{" "}
              <SectionText as="span" color="white">
                {store.user.birth_place_formatted_text}
              </SectionText>
            </SectionText>
          </Item>

          <Item>
            <SectionText>
              Zodiac sign:{" "}
              <SectionText as="span" color="white">
                {capitalizeFirstLetter(store.user.zodiac_sign)}
              </SectionText>
            </SectionText>
          </Item>

          {/* <Item>
            <Stack>
              <SectionText>Areas of interest:</SectionText>

              <Stack spacing={0}>
                {store.user.areas_of_interest.map((it) => {
                  return (
                    <SectionText key={it} color="white">
                      - {capitalizeFirstLetter(it)}
                    </SectionText>
                  );
                })}
              </Stack>
            </Stack>
          </Item>

          <Item>
            <Stack>
              <SectionText>Major life events:</SectionText>

              <Stack spacing={0}>
                {store.user.major_life_events.map((it) => {
                  return (
                    <SectionText key={it} color="white">
                      - {capitalizeFirstLetter(it)}
                    </SectionText>
                  );
                })}
              </Stack>
            </Stack>
          </Item> */}

          <Item>
            <SectionText>
              Relationship status:{" "}
              <SectionText as="span" color="white">
                {store.user.relationship_status
                  ? capitalizeFirstLetter(store.user.relationship_status)
                  : "-"}
              </SectionText>
            </SectionText>
          </Item>
        </Section>

        {permissions.hasSubscription() && (
          <Section>
            <SectionTitle>Subscription</SectionTitle>

            <Stack direction="row" alignItems={"center"}>
              <SectionText>Active</SectionText>
            </Stack>
          </Section>
        )}
      </Stack>
    </Stack>
  );
}
