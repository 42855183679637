export const ASPECTS = [
  "Mars trine Chiron",
  "Mars trine Chiron",
  "Sun square Tenth House",
  "Mars sextile Neptune",
  "Sun square Venus",
  "Venus square Uranus",
  "Mercury sextile Sun",
  "Mercury square Venus",
  "Venus square Chiron",
  "Moon sextile First House",
  "Mars sextile Tenth House",
  "Mercury square Tenth House",
  "Mars sextile First House",
  "Mars sextile Venus",
  "Moon trine Venus",
  "Mercury sextile True Node",
  "Mercury sextile True Node",
  "Venus sextile Sun",
  "Mercury sextile True Node",
  "Moon sextile Venus",
  "Mercury sextile True Node",
  "Moon sextile True Node",
  "Mercury sextile True Node",
  "Mars sextile Mercury",
  "Venus sextile Jupiter",
  "Sun sextile Chiron",
  "Mars square Jupiter",
  "Moon sextile Uranus",
  "Mercury square Tenth House",
  "Mercury square Tenth House",
];

export const TRANSITS = [
  "Sun in gemini at 17.66° in Fourth House",
  "Moon in capricorn at 8.74° in Eleventh House",
  "Mercury in taurus at 25.00° in Third House",
  "Venus in taurus at 25.60° in Third House",
  "Mars in virgo at 3.01° in Seventh House",
  "Jupiter in aries at 13.91° in Second House",
  "Saturn in aquarius at 23.08° in First House Retrogade",
  "Uranus in virgo at 1.57° in Seventh House",
  "Neptune in scorpio at 13.42° in Ninth House Retrogade",
  "Pluto in virgo at 9.63° in Seventh House",
  "Mean Node in cancer at 22.25° in Sixth House Retrogade",
  "True Node in cancer at 20.64° in Fifth House Retrogade",
  "Chiron in pisces at 14.87° in First House",
];
