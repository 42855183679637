import { Stack, Text } from "@chakra-ui/react";
import {
  AstrologerAvatar,
  AstrologerContainer,
  AstrologerForm,
  AstrologerText,
  SuggestedQuestionList,
} from "lib/components";
import { useNavigate } from "react-router";
import { useRootStore } from "stores/useRootStore";

export function AstrologerPage() {
  const store = useRootStore();
  const navigate = useNavigate();

  return (
    <Stack>
      <Stack height={"40px"} />

      <AstrologerContainer>
        <AstrologerAvatar animationDuration={3000} />
        <AstrologerText mb={4}>
          <Text as="span" fontWeight={"bold"} fontSize={"md"}>
            {store.user.name === "" || store.user.name == null
              ? "Dear"
              : store.user.name}
            ,
          </Text>{" "}
          <br />
          Ask me your own question or choose from the list of suggested
          questions
        </AstrologerText>

        <AstrologerForm
          onSubmit={(question) => {
            navigate(`/astrologer/new-instant-reading?question=${question}`);
          }}
        />

        <Stack>
          <Stack
            role="group"
            cursor={"pointer"}
            onClick={() => navigate("/face-reading")}
            spacing={1}
            display={"inline-flex"}
            p={2}
            borderRadius={"xl"}
            whiteSpace="normal"
            alignItems={"flex-start"}
            bg="#805ad529"
            direction={"column"}
          >
            <Stack
              spacing={1}
              px={2}
              direction={"row"}
              flexShrink={0}
              height={"25px"}
              justifyContent={"center"}
              alignItems={"center"}
              bg="purple.800"
              _groupHover={{
                bg: "purple.800",
              }}
              transition={"ease-in 100ms"}
              borderRadius={"lg"}
              flexGrow={0}
              position={"relative"}
            >
              <Text fontSize={"xs"}>{"👱"}</Text>

              <Text fontSize={"xs"} fontWeight={"semibold"} color="purple.50">
                Personality
              </Text>
            </Stack>

            <Text
              color="inherit"
              textAlign={"left"}
              fontWeight={"semibold"}
              _groupHover={{
                textDecoration: "underline",
              }}
            >
              Your face reading
            </Text>
          </Stack>

          <SuggestedQuestionList />
        </Stack>
      </AstrologerContainer>
    </Stack>
  );
}
