import { Stack } from "@chakra-ui/react";
import { Cache } from "lib/cache";
import { Loading } from "lib/components";
import { useLazyRef } from "lib/reactHooks";
import React from "react";
import { User } from "services/coreapi";
import { useServices } from "services/useServices";

type UserCache = {
  userID: string;
};

const USER_CACHE_VERSION = 2;
const USER_CACHE_KEY = "intuvist-user";
const USER_ID_SEARCH_PARAM = "userID";

export type AuthStoreType = {
  user: User | null;
  setUser: (user: User) => void;
  isAuthenticated: () => boolean;
  login: (email: string) => Promise<void>;
  logout: () => void;
};

export const AuthStoreCtx = React.createContext<AuthStoreType | null>(null);

export function AuthStore({ children }: React.PropsWithChildren) {
  const { coreapi, tracking } = useServices();
  const userCache = useLazyRef(
    () => new Cache<UserCache>(USER_CACHE_VERSION, USER_CACHE_KEY),
  );

  const [user, setUser] = React.useState<User | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    async function autoLogin() {
      try {
        setLoading(true);

        const search = new URLSearchParams(location.search);
        const userID =
          search.get(USER_ID_SEARCH_PARAM) ??
          userCache.current.getCache()?.userID;

        if (!userID) {
          userCache.current.deleteCache();
          setUser(null);

          return;
        }

        const user = await coreapi.getUserByID(userID);
        userCache.current.setCache({ userID: user.id });
        setUser(user);
      } catch (err) {
        // TODO: handle

        userCache.current.deleteCache();
        setUser(null);
      } finally {
        setLoading(false);
      }
    }

    autoLogin();
  }, [userCache, coreapi]);

  const value = React.useMemo<AuthStoreType>(() => {
    return {
      user,
      isAuthenticated: () => {
        return Boolean(user);
      },
      login: async (email: string) => {
        const user = await coreapi.getUserByEmail(email);

        userCache.current.setCache({ userID: user.id });
        setUser(user);
      },
      logout: () => {
        userCache.current.deleteCache();
        setUser(null);
      },
      setUser: (user: User) => {
        setUser(user);
      },
    };
  }, [userCache, coreapi, user]);

  React.useEffect(() => {
    if (user) {
      if (user.email) {
        tracking.identify(user.email);
      }
    }
  }, [user]);

  if (loading) {
    return (
      <Stack minH={"100vh"} alignItems={"center"} justifyContent={"center"}>
        <Loading />
      </Stack>
    );
  }

  return (
    <AuthStoreCtx.Provider value={value}>{children}</AuthStoreCtx.Provider>
  );
}
