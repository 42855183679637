import { Box, Icon, Image, Stack, Text } from "@chakra-ui/react";
import User1Img from "assets/user1.jpg";
import User2Img from "assets/user2.jpg";
import User3Img from "assets/user3.jpg";
import User4Img from "assets/user4.jpg";
import User5Img from "assets/user5.jpg";
import User7Img from "assets/user7.jpg";
import User8Img from "assets/user8.jpg";
import User9Img from "assets/user9.jpg";
import { CTAButton } from "lib/components";
import React from "react";
import { FaArrowRight } from "react-icons/fa";

import { ScoreSection } from "./ScoreSection";

export function TestimonialSection({
  onCTAClick,
  sectionID,
}: {
  onCTAClick: (sectionID: string) => void;
  sectionID: string;
}) {
  return (
    <Stack spacing={8} mt={12}>
      <Stack spacing={1}>
        <Text
          textAlign={"center"}
          fontSize={"2xl"}
          fontWeight={"semibold"}
          lineHeight={1.3}
          color={"white"}
        >
          9 Out of 10 Users Love Their AI Astrologers
        </Text>

        <ScoreSection />
      </Stack>

      <Stack
        spacing={7}
        id="product-section"
        backgroundColor={"white"}
        p={4}
        py={6}
        borderRadius={"xl"}
      >
        <TestimonialCard
          testimonial={{
            name: "Harper Wilson",
            quote: `Love my astrologer Sophia! I spent entire weekend experimenting and it took me sooooooo deep into my explorations 🙏🙏🙏`,
            question: "",
            avatar: <Image alt="Intuvist user image" src={User1Img} />,
            commentAge: "4h",
          }}
        />

        <TestimonialCard
          testimonial={{
            name: "Josh Hudson",
            quote: `3 weeks in and so far the astrological insights have been pretty spot on`,
            question: "",
            avatar: <Image alt="Intuvist user image" src={User8Img} />,
            commentAge: "3d",
          }}
        />

        <TestimonialCard
          testimonial={{
            name: "Zuri Thomas",
            quote: `By the way, I have a friend whose wife tried this app before marriage. She entered his chart and received this answer: “This man will either get happily married to you or he will break your heart because of his open relationship.” And until now, they are still together and married... Turned out to be a nice story I keep telling now. Xxx
          `,
            question: "",
            avatar: <Image alt="Intuvist user image" src={User4Img} />,
            commentAge: "3d",
          }}
        />

        <TestimonialCard
          testimonial={{
            name: "Paige Madison Hartley",
            quote: `well that basically can now replace my astrologer here in Greeley haha 😅`,
            question: "",
            avatar: <Image alt="Intuvist user image" src={User3Img} />,
            commentAge: "4d",
          }}
        />

        <TestimonialCard
          testimonial={{
            name: "Jessica Nowak",
            quote: `I was initially skeptical, but I must now admit that I am thoroughly impressed. The accuracy with which the Luna interprets my birth chart is remarkable. The predictions are also accurate, offers glimpses into potential opportunities and challenges on the horizon`,
            question: "",
            avatar: <Image alt="Intuvist user image" src={User7Img} />,
            commentAge: "7d",
          }}
        />

        <TestimonialCard
          testimonial={{
            name: "Lauren Phelps",
            quote: `bought it.`,
            question: "",
            avatar: <Image alt="Intuvist user image" src={User9Img} />,
            commentAge: "8d",
          }}
        />

        <TestimonialCard
          testimonial={{
            name: "Scott Darden",
            quote: `I think I've gotten used to the habit of checking my daily signals every morning when I wake up. Convinced my partner to try it too, so I can see him using his astrologer secretly when I pretend I'm not watching 🫣`,
            question: "",
            avatar: <Image alt="Intuvist user image" src={User2Img} />,
            commentAge: "11d",
          }}
        />

        <TestimonialCard
          testimonial={{
            name: "Theresa Estrada",
            quote: `Hey Intuvist team!\nJust wanted to say that out of all the astrological apps I have tried (which is waaaaay more than 10 😅), I must say this one has been able to deliver accurate insights very consistently. Good job and keep waiting for more improvements!`,
            question: "",
            avatar: <Image alt="Intuvist user image" src={User5Img} />,
            commentAge: "13d",
          }}
        />

        <CTAButton mt={4} onClick={() => onCTAClick(sectionID)}>
          Unlock My Astrologer Now
          <Icon ml={2} as={FaArrowRight} fontSize={"sm"} />
        </CTAButton>
      </Stack>
    </Stack>
  );
}

export type Testimonial = {
  name: string;
  quote: string;
  question: string;
  avatar: React.ReactNode;
  commentAge: string;
};

const TestimonialCard = ({ testimonial }: { testimonial: Testimonial }) => {
  return (
    <Stack spacing={2} direction={"row"}>
      <Box
        flexShrink={0}
        width={8}
        height={8}
        bg="gray.300"
        borderRadius={"full"}
        overflow={"hidden"}
      >
        {testimonial.avatar}
      </Box>
      <Stack>
        <Stack spacing={0} bg={"gray.200"} px={4} py={2} borderRadius={"14px"}>
          <Text color="black" fontWeight={600} fontSize={"13px"}>
            {testimonial.name}
          </Text>
          <Text color="black" fontSize={"13px"} whiteSpace={"pre-wrap"}>
            {testimonial.quote}
          </Text>
        </Stack>

        <Stack
          px={2}
          direction={"row"}
          fontSize={"xs"}
          color="gray.500"
          fontWeight={"bold"}
        >
          <Text color="gray.700" fontWeight={"regular"}>
            {testimonial.commentAge}
          </Text>
          <Text>Like</Text>
          <Text>Reply</Text>
        </Stack>
      </Stack>
    </Stack>
  );
};
