import {
  Icon,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { CTAButton } from "lib/components";
import { FaArrowRight } from "react-icons/fa";

export function ComparisonTable({
  onCTAClick,
  sectionID,
}: {
  onCTAClick: (sectionID: string) => void;
  sectionID: string;
}) {
  const borderColor = "whiteAlpha.400";
  const backgroundColor = "transparent";
  const rowData: Array<
    Array<{
      text: string;
      color: string;
      backgroundColor: string;
      fontWeight: number;
    }>
  > = [
    [
      {
        text: "Personalized readings",
        color: "white",
        fontWeight: 400,
        backgroundColor,
      },
      {
        text: "✅",
        color: "white",
        fontWeight: 400,
        backgroundColor,
      },
      {
        text: "✅",
        color: "white",
        fontWeight: 800,
        backgroundColor: "purple.800",
      },
    ],
    [
      {
        text: "In-depth analysis",
        color: "white",
        fontWeight: 400,
        backgroundColor,
      },
      {
        text: "✅",
        color: "white",
        fontWeight: 400,
        backgroundColor,
      },
      {
        text: "✅",
        color: "white",
        fontWeight: 800,
        backgroundColor: "purple.800",
      },
    ],
    [
      {
        text: "Accuracy",
        color: "white",
        fontWeight: 400,
        backgroundColor,
      },
      {
        text: "✅",
        color: "white",
        fontWeight: 400,
        backgroundColor,
      },
      {
        text: "✅",
        color: "white",
        fontWeight: 800,
        backgroundColor: "purple.800",
      },
    ],
    [
      {
        text: "Fully anonymous",
        color: "white",
        fontWeight: 400,
        backgroundColor,
      },
      {
        text: "-",
        color: "white",
        fontWeight: 800,
        backgroundColor,
      },
      {
        text: "✅",
        color: "white",
        fontWeight: 800,
        backgroundColor: "purple.800",
      },
    ],
    [
      {
        text: "Human interaction",
        color: "white",
        fontWeight: 400,
        backgroundColor,
      },
      {
        text: "✅",
        color: "white",
        fontWeight: 800,
        backgroundColor,
      },
      {
        text: "-",
        color: "white",
        fontWeight: 800,
        backgroundColor: "purple.800",
      },
    ],
    [
      {
        text: "Session duration",
        color: "white",
        fontWeight: 400,
        backgroundColor,
      },
      {
        text: "1 hour",
        color: "red.300",
        fontWeight: 800,
        backgroundColor,
      },
      {
        text: "Unlimited",
        color: "white",
        fontWeight: 800,
        backgroundColor: "purple.800",
      },
    ],
    [
      {
        text: "Price",
        color: "white",
        fontWeight: 400,
        backgroundColor,
      },
      {
        text: "~ $149 per hour",
        color: "red.300",
        fontWeight: 800,
        backgroundColor,
      },
      {
        text: `$0.83 per day`,
        color: "white",
        fontWeight: 800,
        backgroundColor: "purple.800",
      },
    ],
  ];

  return (
    <Stack spacing={5} mt={12}>
      <Text
        textAlign={"center"}
        fontSize={"2xl"}
        fontWeight={"semibold"}
        lineHeight={1.3}
        color={"white"}
      >
        Unlimited Readings for a Fraction of the Cost
      </Text>

      <Text
        textAlign={"center"}
        fontSize={"md"}
        lineHeight={1.3}
        color={"white"}
        px={2}
        mb={3}
      >
        Experience the same accuracy and quality for a much lower price, without
        any time constraints.
      </Text>

      <TableContainer fontSize={"sm"}>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th
                style={{ textWrap: "wrap" }}
                textAlign={"center"}
                borderColor={borderColor}
                borderWidth="1px"
                px={2}
                py={2}
                fontWeight={600}
                color={"white"}
              />
              <Th
                style={{ textWrap: "wrap" }}
                textAlign={"center"}
                borderColor={borderColor}
                borderWidth="1px"
                px={2}
                py={2}
                fontWeight={"bold"}
                color={"white"}
              >
                Your local astrologer
              </Th>
              <Th
                style={{ textWrap: "wrap" }}
                textAlign={"center"}
                backgroundColor={"purple.800"}
                borderColor={borderColor}
                borderWidth="1px"
                px={2}
                py={2}
                fontWeight={"bold"}
                color={"white"}
              >
                Personal AI astrologer
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {rowData.map((row, rowIdx) => (
              <Tr key={rowIdx}>
                {row.map((cell, cellIdx) => {
                  return (
                    <Td
                      style={{ textWrap: "wrap" }}
                      textAlign={"center"}
                      key={cellIdx}
                      px={2}
                      py={2}
                      borderColor={
                        rowIdx === 6 && cellIdx === 2 ? "#44ce44" : borderColor
                      }
                      borderWidth={
                        rowIdx === 6 && cellIdx === 2 ? "6px" : "1px"
                      }
                      backgroundColor={cell.backgroundColor}
                      color={cell.color}
                      fontWeight={cell.fontWeight}
                    >
                      {cell.text}
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <CTAButton mt={4} onClick={() => onCTAClick(sectionID)}>
        Unlock My Astrologer Now
        <Icon ml={2} as={FaArrowRight} fontSize={"sm"} />
      </CTAButton>
    </Stack>
  );
}
