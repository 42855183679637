import { Box, Flex, Image, Text } from "@chakra-ui/react";
import SafeCheckoutImg from "assets/safe-checkout.png";

export function SafeCheckout() {
  return (
    <Flex
      px={2}
      py={2}
      borderRadius={8}
      border={"1px solid"}
      borderColor="whiteAlpha.400"
      flexDirection={"column"}
      alignItems={"flex-start"}
      gap={1}
    >
      <Text
        fontSize={"xs"}
        color="bg.500"
        fontWeight={"semibold"}
        lineHeight={"normal"}
      >
        Safe Checkout Guarantee
      </Text>
      <Box mx="auto">
        <Image alt="Safe checkout" src={SafeCheckoutImg} />
      </Box>
    </Flex>
  );
}
