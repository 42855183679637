import { Flex, Image, Stack, StackProps, Text } from "@chakra-ui/react";
import Logo from "assets/intuvist-logo-white-h.png";
import { APP_CONFIG } from "data/subscriptions";

export function DisclaimerSection(props: StackProps) {
  return (
    <Stack spacing={3} mt={12} {...props}>
      <Image src={Logo} width={"100px"} />

      <Flex flexDirection="column" gap={2}>
        <Text fontSize="11px">
          The information presented on {APP_CONFIG.urlTitle} is solely intended
          for informational purposes. The website does not provide any
          professional advice to its users. Content on this website should not
          be utilized as a replacement for direct professional advice. By
          accessing the content of this website, you confirm that you fully
          acknowledge and comprehend the responsibility you hold for your
          well-being. By accepting an array of information on{" "}
          {APP_CONFIG.urlTitle}, you waive any claims that you may have as a
          result of using the content on {APP_CONFIG.urlTitle}
        </Text>

        <Text id="disclaimer" fontSize="11px">
          © 2024 {APP_CONFIG.urlTitle} - All Rights Reserved
        </Text>
      </Flex>
    </Stack>
  );
}
