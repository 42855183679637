import { Box, Text } from "@chakra-ui/react";

export function PageNotFound() {
  return (
    <Box id="error-page">
      <Text as="h1">Oops!</Text>
      <Text>Sorry, an unexpected error has occurred.</Text>
    </Box>
  );
}
