import { Text } from "@chakra-ui/react";
import { ComponentProps } from "react";

export * from "./Orb";
export * from "./ScrollToTop";
export * from "./ScrollToHashElement";
export * from "./Loading";
export * from "./Astrologer";
export * from "./ResourceRenderer";
export * from "./Skeleton";
export * from "./UserProfileWidget";
export * from "./SuggestedQuestionList";
export * from "./CTAButton";

export function Span(props: ComponentProps<typeof Text>) {
  return <Text as="span" {...props}></Text>;
}
