import { Stack, StackProps } from "@chakra-ui/react";
import OrbBGIMG from "assets/orb-bg-1.png";

export function AstrologerContainer(props: StackProps) {
  return (
    <Stack
      spacing={6}
      backgroundColor="cosmos"
      color="white"
      backgroundImage={`url("${OrbBGIMG}")`}
      backgroundRepeat="no-repeat"
      backgroundSize="100%"
      {...props}
    />
  );
}
