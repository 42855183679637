import React from "react";

import { LoadingState } from "../types";
import { ItemWithDuration, addDurations } from "./utils";

export function useLoadingProgressItems(loadingState: LoadingState): string[] {
  const timeouts = React.useRef<number[]>([]);

  const [allItems, setAllItems] = React.useState<Array<ItemWithDuration>>([]);
  const [items, setItems] = React.useState<string[]>([]);

  React.useEffect(() => {
    timeouts.current.forEach(clearTimeout);
    const items = addDurations(
      loadingState.progressItems,
      loadingState.progressAnimationDuration,
    );
    setAllItems(items);
    setItems([]);
  }, [loadingState]);

  React.useEffect(() => {
    let durationSum = 0;

    allItems.forEach((it) => {
      timeouts.current.push(
        window.setTimeout(() => {
          setItems((log) => [...log, it.item]);
        }, durationSum),
      );
      durationSum = durationSum + it.duration;
    });
  }, [allItems]);

  return items;
}
