import React from "react";
import { Navigate, useLocation, useMatch } from "react-router-dom";
import { useAuthStore } from "stores/useAuthStore";

export function RouteGuard({ children }: React.PropsWithChildren) {
  const loginMatch = useMatch("/login");
  const homeMatch = useMatch("/");
  const location = useLocation();
  const auth = useAuthStore();

  if (homeMatch) {
    return <Navigate to={"/astrologer" + location.search} replace={true} />;
  }

  if (loginMatch && auth.isAuthenticated()) {
    return <Navigate to={"/astrologer" + location.search} replace={true} />;
  }

  if (!loginMatch && !auth.isAuthenticated()) {
    return <Navigate to={"/login" + location.search} replace={true} />;
  }

  return children;
}
