import React from "react";

import { ReadingRepoCtx, ReadingRepoType } from "./ReadingRepo";

export function useReadingRepo(): ReadingRepoType {
  const ctx = React.useContext(ReadingRepoCtx);
  if (!ctx) {
    throw new Error("useReadingRepo has to be used within ReadingRepo");
  }

  return ctx;
}
