import { Box, Stack, StackProps, Text } from "@chakra-ui/react";
import { ResolvedPricingPlan } from "data/subscriptions";
import { Span } from "lib/components";

export function CartPreview({
  plan,
  ...rest
}: { plan: ResolvedPricingPlan } & StackProps) {
  return (
    <Stack
      color="black"
      {...rest}
      border={"1px solid"}
      borderColor="gray.300"
      borderRadius={"lg"}
      p={2}
    >
      {plan.lookupID === "lifetime-license" && (
        <>
          <CartListItem title={"3 astrology gifts"} priceBefore={169} />
          <Box height={"1px"} backgroundColor="blackAlpha.300" width={"full"} />
        </>
      )}

      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        py={3}
      >
        <Stack>
          <Text fontSize={"sm"} fontWeight={"semibold"}>
            Total amount billed
          </Text>
          <Text fontSize={"xs"}>{plan.billingText}</Text>
        </Stack>

        <Stack spacing={0}>
          <Text fontWeight={"bold"} fontSize={"xl"} textAlign={"right"}>
            ${plan.unitAmountNow.toFixed(2)}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
}

function CartListItem(props: {
  title: string;
  priceBefore: number;
  text?: string;
  discount?: {
    discountAbs: string | number;
    discountPercentage: number;
  };
}) {
  return (
    <Stack spacing={0}>
      <Stack direction={"row"} justifyContent={"space-between"} gap={3}>
        <Text fontSize={"sm"} fontWeight={"semibold"}>
          {props.title}
        </Text>

        <Text
          fontWeight={"semibold"}
          flex={2}
          textAlign={"right"}
          fontSize={"sm"}
        >
          <Span textDecoration={"line-through"}>
            ${props.priceBefore.toFixed(2)}
          </Span>
          <Span color="green.600"> FREE</Span>
        </Text>
      </Stack>

      {props.text && (
        <Text fontSize={"xs"} mb={2}>
          {props.text}
        </Text>
      )}

      {props.discount && props.discount.discountAbs !== 0 && (
        <Stack direction={"row"} justifyContent={"space-between"} gap={3}>
          <Text fontSize={"xs"} color="red.500" fontWeight={"bold"}>
            Special discount (-{props.discount.discountPercentage}%)
          </Text>
        </Stack>
      )}
    </Stack>
  );
}
