import { Box, Stack, Text } from "@chakra-ui/react";
import { Skeleton } from "lib/components";
import React from "react";

import { LoadingState } from "../types";
import { useLoadingProgressItems } from "./useLoadingProgressItems";

export function AstrologerLoading({
  loadingState,
}: {
  loadingState: LoadingState;
}) {
  const container = React.useRef<HTMLDivElement>(null);
  const progressItems = useLoadingProgressItems(loadingState);

  // Auto scroll to bottom
  React.useEffect(() => {
    if (container.current) {
      container.current.scrollTop = container.current.scrollHeight;
    }
  }, [progressItems]);

  return (
    <Stack>
      <Box width={"full"} position={"relative"}>
        <Skeleton height={"50px"} width={"100%"} isLoaded={false} />

        <Text
          position={"absolute"}
          fontSize={"sm"}
          fontWeight={"normal"}
          lineHeight={1}
          textAlign={"center"}
          top={"50%"}
          transform={"translateY(-50%)"}
          width={"100%"}
        >
          {loadingState.title}
        </Text>
      </Box>

      <Stack overflow={"hidden"} height={"200px"} position={"relative"}>
        <Stack overflow={"hidden"} ref={container} spacing={0}>
          {progressItems.map((it, idx) => {
            return (
              <Text
                fontWeight={
                  loadingState.id === "important-aspects" ? "bold" : "normal"
                }
                fontFamily={"monospace"}
                fontSize={"xs"}
                color={
                  loadingState.id === "important-aspects"
                    ? "whiteAlpha.800"
                    : "whiteAlpha.500"
                }
                key={idx}
              >
                {it}
              </Text>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
}
