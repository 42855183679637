import { Stack, Text, TextProps } from "@chakra-ui/react";
import { APP_CONFIG } from "data/subscriptions";
import { SecondaryTopNav } from "src/layouts/SecondaryTopNav";

function SectionTitle(props: TextProps) {
  return (
    <Text
      textAlign={"left"}
      fontSize={"md"}
      fontWeight={"bold"}
      color="text.main"
      {...props}
    />
  );
}

export function ContactsPage() {
  return (
    <Stack spacing={8}>
      <SecondaryTopNav />

      <Stack spacing={3}>
        <SectionTitle>Customer support</SectionTitle>

        <Text>
          If you need help with the order, product or payments.
          <br />
          We answer all of the emails within 24-72 hours (including weekends!)
        </Text>

        <a style={{ color: "#005eed" }} href={`mailto:${APP_CONFIG.email}`}>
          {APP_CONFIG.email}
        </a>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle> Partnerships</SectionTitle>

        <Text>
          If you are a content creator or affiliate and would like to work with
          us
        </Text>

        <a style={{ color: "#005eed" }} href={`mailto:${APP_CONFIG.email}`}>
          {APP_CONFIG.email}
        </a>
      </Stack>

      <Stack spacing={3}>
        <SectionTitle>Feedback</SectionTitle>

        <Text>
          If you want to share any feedback with us or just to say a good word.
        </Text>

        <a style={{ color: "#005eed" }} href={`mailto:${APP_CONFIG.email}`}>
          {APP_CONFIG.email}
        </a>
      </Stack>
    </Stack>
  );
}
