import { Stack } from "@chakra-ui/react";
import { AstrologerQuestionBtn, ResourceRenderer } from "lib/components";
import { IconType } from "react-icons";
import { FaHeart } from "react-icons/fa";
import { FaPaperPlane } from "react-icons/fa";
import { FaAppleAlt } from "react-icons/fa";
import { GiMeditation } from "react-icons/gi";
import { HiLightBulb } from "react-icons/hi";
import { MdBusinessCenter } from "react-icons/md";
import { useReadingRepo } from "repositories/useReadingRepo";

type ThemeID =
  | "relationships"
  | "career"
  | "wellbeing"
  | "personality"
  | "decision-making"
  | "exploration";

const themeMap: Record<
  ThemeID,
  { icon: IconType; emoji: string; title: string }
> = {
  career: {
    icon: MdBusinessCenter,
    emoji: "💼",
    title: "Career",
  },
  "decision-making": {
    icon: HiLightBulb,
    emoji: "💡",
    title: "Decisions",
  },
  exploration: {
    icon: FaPaperPlane,
    emoji: "✈️",
    title: "Exploration",
  },
  personality: {
    icon: GiMeditation,
    emoji: "👱",
    title: "Personality",
  },
  relationships: {
    icon: FaHeart,
    emoji: "❤️",
    title: "Relationships",
  },
  wellbeing: {
    icon: FaAppleAlt,
    emoji: "🍀",
    title: "Wellbeing",
  },
};

export function SuggestedQuestionList() {
  const readingRepo = useReadingRepo();

  return (
    <ResourceRenderer resource={readingRepo.suggestedQuestions} fallback="">
      {({ data }) => (
        <Stack spacing={2} mb={10}>
          {data.map((it) => {
            return (
              <AstrologerQuestionBtn
                key={it.id}
                text={it.text}
                theme={themeMap[it.theme as ThemeID]}
              />
            );
          })}
        </Stack>
      )}
    </ResourceRenderer>
  );
}
