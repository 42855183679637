import { DomainErrorType, ServerResponseError } from "./types";

export function createCoreApiError(error: ServerResponseError) {
  if (typeof error.value === "string") {
    return new CoreApiServerError();
  }

  switch (error.value.code) {
    case "DOMAIN": {
      return new CoreApiDomainError({
        type: error.value.type!,
        userMessage: error.value.userMessage,
      });
    }
    default: {
      return new CoreApiServerError();
    }
  }
}

export class CoreApiServerError extends Error {
  constructor() {
    super();
  }
}

export class CoreApiDomainError extends Error {
  type: DomainErrorType;
  userMessage?: string;

  constructor(input: { type: DomainErrorType; userMessage?: string }) {
    super(input.userMessage ?? input.type);
    this.type = input.type;
    this.userMessage = input.userMessage;
  }
}
