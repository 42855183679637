import { Stack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export function Footer() {
  return (
    <Stack
      direction={["column", "row"]}
      alignItems={"center"}
      justifyContent={"center"}
      spacing={[1, 3]}
    >
      <Link
        to={"https://intuvist.com/terms-and-conditions"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text
          color="whiteAlpha.600"
          cursor={"pointer"}
          as="span"
          fontSize={"sm"}
        >
          Terms & Conditions
        </Text>
      </Link>

      <Link
        to={"https://intuvist.com/privacy-policy"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text
          color="whiteAlpha.600"
          cursor={"pointer"}
          as="span"
          fontSize={"sm"}
        >
          Privacy Policy
        </Text>
      </Link>
    </Stack>
  );
}
