import { Flex, Image, Stack, Text } from "@chakra-ui/react";
import Cosmopolitan from "assets/media-cosmopolitan.svg";
import Guardian from "assets/media-guardian.svg";
import NYT from "assets/media-nyt.svg";
import Vogue from "assets/media-vogue.svg";

export function MediaSection() {
  return (
    <Stack id="media-coverage" gap={4} alignItems={"center"} mt={8}>
      <Text fontSize={"md"} color="whiteAlpha.700" fontWeight={"semibold"}>
        Personalized AI astrology featured in:
      </Text>

      <Flex width={"100px"} opacity={1}>
        <Image alt="The guardian" src={Guardian} />
      </Flex>

      <Flex flexDirection={"row"} alignItems={"center"} gap={6}>
        <Flex width={"120px"} opacity={1}>
          <Image alt="Cosmopolitan" src={Cosmopolitan} />
        </Flex>

        <Flex width={"80px"} opacity={1}>
          <Image alt="Vogue" src={Vogue} />
        </Flex>
      </Flex>

      <Flex width={"200px"} opacity={1}>
        <Image alt="New york times" src={NYT} />
      </Flex>
    </Stack>
  );
}
